/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  useIndividualReportsFilterQuery,
  useIndividualExcessiveOwnerOccupancyQuery,
  useGetPaginatedLandlordIncomeSummaryQuery,
} from "graphql/hasura/types-and-hooks";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import React from "react";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import { useAppErrorHandler } from "errors/app.errors";

import useExportData from "exports/useExportData";

import {
  noReturnsColumns,
  excessiveOccupancyColumns,
  noIncomeColumns,
  underreportedColumns,
  compliantColumns,
  unregisteredColumns,
} from "./analysis-tab.schema";

import { LandlordIncomeSummaryColumns } from "../../non-individual-tab/analysis-tab/analysis-tab.schema";

import SC from "./analysis-tab.styles";

interface AnalysisComponentProps {
  rnid: string;
  tin: string;
  customerId: string;
}

const AnalysisComponent: React.FC<AnalysisComponentProps> = ({
  rnid,
  customerId,
  tin,
}) => {
  const unregisteredQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: rnid } },
        { Compliancecategory: { _eq: "NO MATCH" } },
      ],
    },
  };

  const noIncomeQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: rnid } },
        { Compliancecategory: { _eq: "NO INCOME" } },
      ],
    },
  };

  const noReturnQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: rnid } },
        { Compliancecategory: { _eq: "NO RETURN" } },
      ],
    },
  };

  const underReportedQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: rnid } },
        { Compliancecategory: { _eq: "UNDERREPORTED" } },
      ],
    },
  };

  const compliantQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: rnid } },
        { Compliancecategory: { _eq: "COMPLIANT" } },
      ],
    },
  };

  const excessiveOccupancyQueryVariables = {
    where: {
      _and: [
        tin
          ? { UraTin: { _eq: tin } }
          : { CustomerId: { _eq: customerId.split(",") } },
      ],
    },
  };

  const declaredVsReportedQueryVariables = {
    where: {
      LandlordTin: { _eq: tin },
    },
  };

  const {
    data: unregisteredData,
    loading: unregisteredLoading,
    error: unregisteredError,
  } = useIndividualReportsFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(unregisteredQueryVariables ?? {}),
    },
  });

  const {
    data: noIncomeData,
    loading: noIncomeLoading,
    error: noIncomeError,
  } = useIndividualReportsFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(noIncomeQueryVariables ?? {}),
    },
  });

  const {
    data: noReturnData,
    loading: noReturnLoading,
    error: noReturnError,
  } = useIndividualReportsFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(noReturnQueryVariables ?? {}),
    },
  });

  const {
    data: underReportedData,
    loading: underReportedLoading,
    error: underReportedError,
  } = useIndividualReportsFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(underReportedQueryVariables ?? {}),
    },
  });

  const {
    data: compliantData,
    loading: compliantLoading,
    error: compliantError,
  } = useIndividualReportsFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(compliantQueryVariables ?? {}),
    },
  });

  const {
    data: excessiveOccupancyData,
    loading: excessiveOccupancyLoading,
    error: excessiveOccupancyError,
  } = useIndividualExcessiveOwnerOccupancyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin && !customerId,
    variables: {
      params: jsonToStringQueryVariables(
        excessiveOccupancyQueryVariables ?? {}
      ),
    },
  });

  const {
    data: declaredVsReportedData,
    loading: declaredVsReportedLoading,
    error: declaredVsReportedError,
  } = useGetPaginatedLandlordIncomeSummaryQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !declaredVsReportedQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(
        declaredVsReportedQueryVariables ?? {}
      ),
      aggregateParams: jsonToStringQueryVariables({
        where: declaredVsReportedQueryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(
      "rtcs_db_Fnura_LandlordIncomeSummary",
      declaredVsReportedQueryVariables
    );

  useAppErrorHandler(
    unregisteredError ||
      excessiveOccupancyError ||
      underReportedError ||
      noIncomeError ||
      noReturnError ||
      compliantError ||
      declaredVsReportedError ||
      exportError
  );

  return (
    <SC.Container maxWidth={false}>
      <ProgressIndicator
        open={
          noIncomeLoading ||
          noReturnLoading ||
          underReportedLoading ||
          compliantLoading ||
          unregisteredLoading ||
          excessiveOccupancyLoading ||
          declaredVsReportedLoading ||
          exportLoading
        }
      />
      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Compliant Taxpayers"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={compliantColumns}
          data={
            compliantData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in1"
        />
      </SC.Card>
      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Unregistered Landlords"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          // @ts-ignore
          columns={unregisteredColumns}
          data={
            unregisteredData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in2"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with No Returns"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={noReturnsColumns}
          data={
            noReturnData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in3"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with No Income"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={noIncomeColumns}
          data={
            noIncomeData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in4"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with Underreported Income"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={underreportedColumns}
          data={
            underReportedData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in5"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Excessive Owner Occupancy (Individuals Only)"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={excessiveOccupancyColumns}
          data={
            excessiveOccupancyData?.rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-in6"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          persistenceId="dca67d6b-a513-433b-aa22-328427845524"
          title="Landlord Declared vs Tenant Reported Income"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => {}}
          columns={LandlordIncomeSummaryColumns}
          data={
            declaredVsReportedData?.rtcs_db_Fnura_LandlordIncomeSummary ?? []
          }
          exportData={exportDataFn}
        />
      </SC.Card>
    </SC.Container>
  );
};

export default AnalysisComponent;
