/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Paper,
  Grid,
  Container as ContainerBase,
  withStyles,
} from "@material-ui/core";

import PropertyDetailsBase from "components/property-details/property-details.component";
import ImageList from "components/image-list/image-list.component";

const Container = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    margin: 0,
    overflowY: "scroll",
    height: "100%",
  },
}))(ContainerBase);

const PropertyDetails = withStyles(() => ({}))(PropertyDetailsBase);

export default {
  Paper,
  Grid,
  PropertyDetails,
  ImageList,
  Container,
};
