/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";
import { NiraFragment } from "graphql/hasura/types-and-hooks";

export type NiraSchema = Omit<NiraFragment, "__typename">;

// @ts-ignore
export const NiraSchemaMap: Record<keyof NiraSchema, "string" | "number"> = {
  PseudoNIN: "string",
  Surname: "string",
  NationalID: "string",
  MobileNumber: "string",
  Email: "string",
  GivenName: "string",
  DateOfBirth: "string",
  // BirthAddress: "string",
};

export const getAccessorType = (
  accessor: keyof NiraSchema | Accessor<NiraSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NiraSchemaMap[accessor];
};

export const niraTableColumns: TableColumn<NiraSchema>[] = [
  {
    Header: "First Name",
    accessor: "GivenName",
    align: "left",
    sort: false,
  },
  {
    Header: "Last Name",
    accessor: "Surname",
    align: "left",
    sort: false,
  },
  {
    Header: "Date of Birth",
    accessor: "DateOfBirth",
    align: "center",
    format: "date",
    sort: false,
  },
  {
    Header: "NIN",
    accessor: "NationalID",
    align: "left",
    sort: false,
  },
  {
    Header: "Email",
    accessor: "Email",
    align: "left",
    sort: false,
  },
  {
    Header: "Mobile",
    accessor: "MobileNumber",
    align: "left",
    sort: false,
  },
];
