/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import {
  Query_RootRtcs_Db_Ph_Nwsc_CustomersArgs,
  useNwscCustomerInfoQuery,
} from "graphql/hasura/types-and-hooks";

// GraphQL
import { CLIENT_NAME } from "graphql/client";

import NwscTransactions from "components/nswc-transactions/nwsc-transactions.component";

// Utils
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { useAppErrorHandler } from "errors/app.errors";

import SC from "./utilities-tab.styles";

type UtilitiesTabProps = {
  reference: string;
};

const UtilitiesTab: React.FC<UtilitiesTabProps> = ({ reference }) => {
  // TODO: Integrate the loading flag to display the ProgressIndicator. We should avoid to display
  // multiple proggress indicators. We could create a global progress indicator and send the status
  // by using a hook like useAppProgressIndicator(loading);
  const queryVariables: Query_RootRtcs_Db_Ph_Nwsc_CustomersArgs = {
    where: { Customerreference: { _eq: reference } },
    limit: 1,
  };
  const { data, error } = useNwscCustomerInfoQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !reference,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(error);

  return (
    <>
      <SC.Container maxWidth={false}>
        <SC.Typography variant="h6">
          National Water and Sewerage Corporation(NWSC)
        </SC.Typography>

        <SC.Paper elevation={0}>
          <SC.Grid container>
            <SC.Grid item xs={3}>
              <SC.LabelKeyValue
                label="Customer Name"
                key={0}
                value={data?.rtcs_db_Fnnwsc_Customers[0].CustomernamePay ?? ""}
              />
              <SC.LabelKeyValue
                label="Customer Reference Number"
                key={0}
                value={
                  data?.rtcs_db_Fnnwsc_Customers[0].Customerreference ?? ""
                }
              />
            </SC.Grid>

            <SC.Grid item xs={3}>
              <SC.LabelKeyValue
                label="Phone 1"
                key={0}
                value={
                  data?.rtcs_db_Fnnwsc_Customers[0].Customerphoneno1Pay ?? ""
                }
              />
              <SC.LabelKeyValue
                label="Phone 2"
                key={0}
                value={
                  data?.rtcs_db_Fnnwsc_Customers[0].Customerphoneno2Pay ?? ""
                }
              />
              <SC.LabelKeyValue
                label="Phone 3"
                key={0}
                value={
                  data?.rtcs_db_Fnnwsc_Customers[0].Customerphoneno3Pay ?? ""
                }
              />
            </SC.Grid>

            <SC.Grid item xs={3}>
              <SC.LabelKeyValue label="Email" key={0} value="" />
              <SC.LabelKeyValue
                label="Address"
                key={0}
                value={data?.rtcs_db_Fnnwsc_Customers[0].AreaPay ?? ""}
              />
              <SC.LabelKeyValue
                label="Area"
                key={0}
                value={data?.rtcs_db_Fnnwsc_Customers[0].AddressPay ?? ""}
              />
            </SC.Grid>

            <SC.Grid item xs={3}>
              <SC.LabelKeyValue
                label="Latitude"
                key={0}
                value={data?.rtcs_db_Fnnwsc_Customers[0].LatitudePay ?? ""}
              />
              <SC.LabelKeyValue
                label="Longitude"
                key={0}
                value={data?.rtcs_db_Fnnwsc_Customers[0].LongitudePay ?? ""}
              />
            </SC.Grid>
          </SC.Grid>

          <NwscTransactions reference={reference} exportToExcel />
        </SC.Paper>
      </SC.Container>
    </>
  );
};

export default UtilitiesTab;
