/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-shadow */
// Dependencies
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs,
  useNonIndividualIncomeTaxSummaryQuery,
  useNonIndividualRentExpensesQuery,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Assets
import SC from "./tax-history-details.styles";
import RentalTaxViewDetails from "../rental-tax-view-details/rental-tax-view-details.component";
import RentalTaxViewSummary from "../rental-tax-view-summary/rental-tax-view-summary.component";

const TaxHistoryDetails: React.FC = () => {
  const { rtnNO } = useParams<{ rtnNO: string }>();

  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs =
    {
      where: { RtnNo: { _eq: rtnNO } },
    };

  const {
    loading: rentalReturnLoading,
    data: rentalReturnData,
    error: rentalReturnError,
  } = useNonIndividualIncomeTaxSummaryQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rtnNO,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const {
    loading: rentalExpensesLoading,
    data: rentalExpensesData,
    error: rentalExpensesError,
  } = useNonIndividualRentExpensesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rtnNO,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(rentalReturnError || rentalExpensesError);

  const returns = useMemo(
    () => rentalReturnData?.rtcs_db_FnRPT_NonIndividualTaxSummaryData ?? [],
    [rentalReturnData]
  )[0];

  const expenses = useMemo(
    () => rentalExpensesData?.rtcs_db_Fnura_RentExpenseDetails ?? [],
    [rentalExpensesData]
  );

  if (!returns) return null;

  return (
    <>
      <SC.ProgressIndicator
        open={rentalReturnLoading || rentalExpensesLoading}
      />
      <RentalTaxViewSummary returns={returns} expenses={expenses} />
      <RentalTaxViewDetails returns={returns} expenses={expenses} />
    </>
  );
};

export default TaxHistoryDetails;
