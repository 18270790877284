/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React from "react";

// Component
import SelectableListItem from "components/selectable-list-item/selectable-list-item.component";

// Assets
import SC from "./selectable-list.styles";

export interface SelectableListProps<T extends Record<string, unknown>> {
  items?: T[];
  isEditable: boolean;
  selectedItem?: T;
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
  getItemTitle?: (item: T) => string;
  getItemChecked: (item: T) => boolean;
  onListChange: (selectedItem: T) => void;
  onCheckboxChange: (checkedItem: T) => void;
}

export const SelectableList = <T extends Record<string, unknown>>({
  items = [],
  isEditable,
  selectedItem,
  getItemKey,
  getItemLabel,
  getItemTitle,
  getItemChecked,
  onListChange,
  onCheckboxChange,
}: SelectableListProps<T>): JSX.Element => {
  const handlerListItemOnClick: React.MouseEventHandler<HTMLLIElement> &
    React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLLIElement, MouseEvent>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const selItem = items.find(
        (item) => getItemKey(item) === event.currentTarget.id
      );

      if (selItem) onListChange(selItem);
    },
    [getItemKey, items, onListChange]
  );

  const handlerCheckboxClick: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const checkedItem = items.find(
          // @ts-ignore
          (item) => getItemKey(item) === event.target.id
        );

        if (checkedItem) onCheckboxChange(checkedItem);
      },
      [getItemKey, items, onCheckboxChange]
    );

  return (
    <SC.Box>
      <SC.List dense>
        {items?.map((item: T) => {
          const label = getItemLabel(item);
          const key = getItemKey(item);
          const title = getItemTitle?.(item);
          const isSelected = selectedItem && getItemKey(selectedItem) === key;

          return (
            <SelectableListItem
              key={key}
              id={key}
              label={label}
              title={title}
              checked={getItemChecked(item)}
              isSelected={isSelected}
              isEditable={isEditable}
              onClick={handlerListItemOnClick}
              onCheckBoxClick={handlerCheckboxClick}
            />
          );
        })}
      </SC.List>
    </SC.Box>
  );
};

export default SelectableList;
