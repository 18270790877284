/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { SelectProps } from "@material-ui/core";

// Styles
import SC from "./calendar-select.styles";

export interface Menu {
  index: number;
  label: string;
  value: string;
}

export interface SelectFieldProps extends SelectProps {
  label?: string;
  data: Menu[];
}

export const SelectField = ({ label, data, ...props }: SelectFieldProps) => (
  <SC.FormControl>
    <SC.Select variant="outlined" displayEmpty={false} {...props}>
      <SC.MenuItem value="">{label}</SC.MenuItem>
      {data.map((item: Menu) => (
        <SC.MenuItem key={item.index} value={item.value}>
          {item.label}
        </SC.MenuItem>
      ))}
    </SC.Select>
  </SC.FormControl>
);

export default SelectField;
