/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";

// GraphQL
import {
  PermissionData,
  useGetPermissionsQuery,
  useGetRoleWithPermissionsQuery,
} from "graphql/types-and-hooks";

// Utils
import { useErrorHandler } from "react-error-boundary";

// Components
import {
  RoleFormFields,
  RoleFormFieldsProps,
} from "./role-form-fields.component";

export type RoleFormFieldsContainerProps = Omit<
  RoleFormFieldsProps,
  "permissionList" | "arePermissionsLoading"
>;

export const RoleFormFieldsContainer: React.FC<RoleFormFieldsContainerProps> = (
  props
) => {
  const { roleData } = props;
  const {
    data: rolePermissionsData,
    loading: rolePermissionsLoading,
    error: rolePermissionsError,
  } = useGetRoleWithPermissionsQuery({
    skip: !roleData.id,
    variables: {
      roleId: roleData.id ?? "",
    },
  });
  const {
    data: defaultPermissionsData,
    loading: defaultPermissionsLoading,
    error: defaultPermissionsError,
  } = useGetPermissionsQuery({
    skip: !!roleData.id,
  });

  const rolePermissions = React.useMemo(() => {
    if (rolePermissionsData && rolePermissionsData.GetRole.length > 0) {
      return rolePermissionsData.GetRole[0]?.permissions ?? [];
    }

    return (defaultPermissionsData?.GetPermissions as PermissionData[]) ?? [];
  }, [rolePermissionsData, defaultPermissionsData]);

  useErrorHandler(rolePermissionsError || defaultPermissionsError);

  return (
    <RoleFormFields
      permissionList={rolePermissions}
      arePermissionsLoading={
        rolePermissionsLoading || defaultPermissionsLoading
      }
      {...props}
    />
  );
};

export default RoleFormFieldsContainer;
