/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";

export type NonIndividualTableSchema = {
  TinNo: string;
  Rnid: string;
  Visible: boolean;
  TaxPayerName: string;
  HoldingCompName: string;
  OrgType: string;
  OrgSubType: string;
  OrgSubTypeDesc: string;
};

export const NonIndividualTableColumns: TableColumn<NonIndividualTableSchema>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { Rnid },
        },
      }) => {
        return (
          <Link to={`/non-individuals/${Rnid}/identification`} color="inherit">
            {value}
          </Link>
        );
      },
    },

    {
      Header: "Taxpayers Name",
      accessor: "TaxPayerName",
      align: "left",
    },
    {
      Header: "Holding Company Name",
      accessor: "HoldingCompName",
      align: "left",
    },
    { Header: "Rnid", accessor: "Rnid", hiddenColumn: true },
    {
      Header: "Organisation Type",
      accessor: "OrgType",
      align: "left",
    },
    {
      Header: "Organisation Sub Type",
      accessor: "OrgSubType",
      align: "left",
    },
    { Header: "", accessor: "Visible", hiddenColumn: true },
    {
      Header: "Organisation Sub Type Description",
      accessor: "OrgSubTypeDesc",
      align: "left",
    },
  ];
