/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { OwnersAndOfficersFragment } from "graphql/hasura/types-and-hooks";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export type OfficersAndOwnersSchema = Omit<
  OwnersAndOfficersFragment,
  "__typename"
>;

export const OfficersAndOwnersSchemaMap: Record<
  keyof OfficersAndOwnersSchema,
  "string" | "number"
> = {
  Individualtin: "string",
  Name: "string",
  Tinno: "string",
  Taxpayername: "string",
  Designation: "string",
  Pershare: "number",
  Address: "string",
  Rtnno: "string",
  Rtnperiodyear: "string",
  Rtnholdingofficersdtlid: "string",
  Rtnholdingownersdtlid: "string",
  Rnid: "string",
};

export const getAccessorType = (
  accessor:
    | keyof OfficersAndOwnersSchema
    | Accessor<OfficersAndOwnersSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return OfficersAndOwnersSchemaMap[accessor];
};

export const OfficersAndOwndersTableColumns: TableColumn<OfficersAndOwnersSchema>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
      sort: false,
    },
    {
      Header: "Individual TIN No.",
      accessor: "Individualtin",
      align: "left",
      sort: false,
      Cell: ({
        value,
        row: {
          values: { Individualtin },
        },
      }: {
        value: string;
        row: { values: { Individualtin: string } };
      }): React.ReactNode => {
        return isValidTin(Individualtin) ? (
          <Link
            to={`/individuals/${Individualtin}/identification`}
            color="inherit"
          >
            {value}
          </Link>
        ) : (
          <>{Individualtin}</>
        );
      },
    },
    {
      Header: "Name",
      accessor: "Name",
      align: "left",
      sort: false,
    },
    {
      Header: "Business TIN No.",
      accessor: "Tinno",
      align: "left",
      sort: false,
      Cell: ({
        value,
        row: {
          values: { Tinno },
        },
      }: {
        value: string;
        row: { values: { Tinno: string } };
      }): React.ReactNode => {
        return isValidTin(Tinno) ? (
          <Link to={`/non-individuals/${Tinno}/identification`}>{value}</Link>
        ) : (
          <>{Tinno}</>
        );
      },
    },
    {
      Header: "Business Name",
      accessor: "Taxpayername",
      align: "left",
      sort: false,
    },
    {
      Header: "Designation",
      accessor: "Designation",
      align: "center",
      sort: false,
    },
    {
      Header: "Percent Owned",
      accessor: "Pershare",
      align: "right",
      sort: false,
    },
    {
      Header: "Address",
      accessor: "Address",
      align: "left",
      sort: false,
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "left",
      sort: false,
    },
    {
      Header: "Tax Year",
      accessor: "Rtnperiodyear",
      align: "left",
      sort: false,
    },
    {
      Header: "Officers Index No.",
      accessor: "Rtnholdingofficersdtlid",
      align: "left",
      sort: false,
      hiddenColumn: true,
    },
    {
      Header: "Owners Index No.",
      accessor: "Rtnholdingownersdtlid",
      align: "left",
      sort: false,
      hiddenColumn: true,
    },
  ];
