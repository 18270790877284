/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  GridList as GridListBase,
  GridListTile as GridListTileBase,
  Button as ButtonBase,
  Box,
  withStyles,
} from "@material-ui/core";

import { PhotoLibrary as PhotoLibraryIcon } from "@material-ui/icons";
import ImagePlaceholder from "components/image-placeholder/image-placeholder.component";
import LightBox from "components/lightbox/lightbox.component";
import { Carousel as CarouselBase } from "react-responsive-carousel";

const MainImage = withStyles(() => ({
  root: {
    width: "100%",
  },
}))(GridListBase);

const ImageThumbnails = withStyles((theme: any) => ({
  root: {
    width: "100%",
    height: theme.imageList.height,
    display: "flex",
    justifyContent: "center",
    overflowY: "hidden",
    marginBottom: theme.spacing(2),
  },
}))(GridListBase);

const GridListTile = withStyles(() => ({
  root: {
    cursor: "pointer",
  },
}))(GridListTileBase);

const Button = withStyles(() => ({
  root: {
    top: "50%",
    transform: "translateY(-50%)",
  },
}))(ButtonBase);

const Carousel = withStyles(() => ({
  root: {
    maxHeight: "300px",
  },
  ".slide .selected": {
    maxHeight: "300px",
  },
}))(CarouselBase);

export default {
  Grid,
  MainImage,
  ImageThumbnails,
  GridListTile,
  Button,
  PhotoLibraryIcon,
  LightBox,
  ImagePlaceholder,
  Box,
  Carousel,
};
