/* eslint-disable import/no-extraneous-dependencies */
import _ from "lodash";

const UIErrorCodes = {
  // General
  COULD_NOT_REALIZE_THE_OPERATION: "could-not-realize-the-operation",
} as const;

type UIErrorType = keyof typeof UIErrorCodes;

type UIErrorCode = (typeof UIErrorCodes)[UIErrorType];

const isUIErrorCode = (
  maybeUIErrorCode: unknown
): maybeUIErrorCode is UIErrorCode => {
  return (
    _.isString(maybeUIErrorCode) &&
    Object.values<string>(UIErrorCodes).includes(maybeUIErrorCode)
  );
};

const isUIError = (maybeUIError: Error): maybeUIError is UIError => {
  return maybeUIError.name === "UIError";
};

class UIError extends Error {
  public readonly code: UIErrorCode;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(code: UIErrorCode, message: string, ...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UIError);
    }

    this.name = "UIError";
    this.code = code;
    this.message = message;
  }
}

export { UIError, UIErrorCodes, isUIErrorCode, isUIError };
export type { UIErrorType, UIErrorCode };
