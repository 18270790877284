/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Typography, Card as CardBase } from "@material-ui/core";
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const Heading = withStyles(() => ({
  root: {
    font: "1.42rem",
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: "1rem",
    marginTop: "1.14rem",
  },
}))(Typography);

const Card = withStyles((theme: any) => ({
  root: {
    border: theme.tableCard.card.border,
    borderRadius: theme.tableCard.card.border,
    margin: theme.tableCard.card.margin,
  },
}))(CardBase);

export default {
  Table,
  ProgressIndicator,
  Heading,
  Card,
};
