/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  FormControl as FormControlBase,
  Select as SelectBase,
  MenuItem,
} from "@material-ui/core";

const FormControl = withStyles((theme: any) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    "& .MuiInputBase-root": {
      height: theme.calendarSelectField.height,
    },
  },
}))(FormControlBase);

const Select = withStyles((theme: any) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderWidth: 0,
    textAlign: "left",
    fontSize: theme.calendarSelectField.fontSize,
    color: theme.palette.text.primary,

    "& .MuiSelect-select em": {
      fontStyle: "regular",
    },
  },
}))(SelectBase);

export default { FormControl, Select, MenuItem };
