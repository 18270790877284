/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { withStyles, Card as CardBase } from "@material-ui/core";

const Card = withStyles((theme: any) => ({
  root: {
    border: theme.tableCard.card.border,
    borderRadius: theme.tableCard.card.border,
    margin: theme.tableCard.card.margin,
  },
}))(CardBase);

export default {
  Table,
  ProgressIndicator,
  Card,
};
