/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { fileSave } from "browser-fs-access";
import { saveAs } from "file-saver";

export const handleExportToCSV = (data: any, tableName: string): void => {
  fetch(data)
    .then((res) => res.blob())
    .then((blob) => {
      // fileSave(blob, {
      //   fileName: `${tableName}.zip`,
      //   extensions: [".zip"],
      // });
      saveAs(blob, `${tableName}.zip`);
    });
};

export const handleExportDataToZIP = (data: any, tableName: string): void => {
  const sanitizedData = data.replace(/[^A-Za-z0-9+/=]/g, "");
  const binaryData = atob(sanitizedData);

  // Convert binary data to array of 8-bit unsigned integers
  const byteNumbers = new Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i += 1) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }

  // Create a blob from the array of integers
  const blob = new Blob([new Uint8Array(byteNumbers)], {
    type: "application/xlsx",
  });

  // Save the blob as a zip file
  saveAs(blob, `${tableName}.xlsx`);
};

export const handleExportDataReportToCSV = (
  columns: any,
  data: any,
  tableName: string
) => {
  // Extract the column header, and object key accessor from the columns obj
  const headers: string[] = [];
  const accessors: string[] = [];

  const CSVRows = [];

  columns.map((column: any) => {
    headers.push(column.Header);
    accessors.push(column.id);
    return undefined;
  });

  CSVRows.push(headers.join(","));

  // eslint-disable-next-line no-restricted-syntax
  for (const row of data) {
    const values = accessors.map((accessor: any) => {
      return `"${row[accessor] ? row[accessor] : ""}"`;
    });
    CSVRows.push(values.join(","));
  }

  const csv = CSVRows.join("\n");

  const blob = new Blob([csv], { type: "text/csv" });
  (async () => {
    // Save a file.
    saveAs(blob, `${tableName}.csv`);
    // fileSave(blob, {
    //   fileName: `${tableName}.csv`,
    //   extensions: [".csv"],
    // });
  })();
};

export default handleExportToCSV;
