import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import SC from "./switch-views.styles";

interface SwitchViewsProps {
  LeftView: React.ReactNode;
  leftViewName: string;
  RightView: React.ReactNode;
  rightViewName: string;
}

const SwitchViews: React.FC<SwitchViewsProps> = ({
  LeftView,
  leftViewName,
  RightView,
  rightViewName,
}) => {
  const { search } = useLocation();
  let isMapVissible = false;
  let queryParams = search.split("?");

  if (queryParams.length > 1) {
    queryParams = queryParams[1].split("=");
    if (queryParams.includes("mapview")) {
      const index = queryParams.indexOf("mapview");
      if (queryParams[index + 1] && queryParams[index + 1] === "visible") {
        isMapVissible = true;
      }
    }
  }
  const [switchIsOn, setSwitchIsOn] = useState(isMapVissible);

  const handleSwitch = () => {
    setSwitchIsOn((prevStatus) => !prevStatus);
  };

  return (
    <>
      <SC.Box>
        <SC.Grid container alignItems="center">
          <SC.Box component="h3" fontWeight={switchIsOn ? "normal" : "bold"}>
            {leftViewName}
          </SC.Box>
          <SC.Switch
            checked={switchIsOn}
            onChange={handleSwitch}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <SC.Box component="h3" fontWeight={switchIsOn ? "bold" : "normal"}>
            {rightViewName}
          </SC.Box>
        </SC.Grid>
      </SC.Box>
      {switchIsOn ? RightView : LeftView}
    </>
  );
};

export default SwitchViews;
