/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import {
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  Rtcs_Db_Ph_Ura_RentExpenseDetails,
} from "graphql/hasura/types-and-hooks";

import {
  modelOtherExpenses,
  modelFinancingExpenses,
  modelAdministrativeExpenses,
  modelGrossRentalIncome,
  modelSubTitles,
} from "./rental-tax-view-details.schema";

// Assets
import SC from "./rental-tax-view-details.styles";

interface InputProps {
  returns: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData;
  expenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[];
}

const RentalTaxViewDetails: React.FC<InputProps> = ({ returns, expenses }) => {
  const getExpenseOfCategory = (
    expenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[] | undefined,
    category: string
  ) =>
    expenses?.filter(
      (expense) => expense.Expensetype?.toLowerCase() === category
    );

  return (
    <SC.TaxHistoryAccordion
      title="Rental Tax View Details"
      subtitles={modelSubTitles(returns)}
    >
      <SC.NestedList
        data={modelGrossRentalIncome(
          returns,
          getExpenseOfCategory(expenses, "operating expense")
        )}
      />
      <SC.NestedList
        data={modelAdministrativeExpenses(
          returns,
          getExpenseOfCategory(expenses, "administrative expense")
        )}
      />
      <SC.NestedList
        data={modelFinancingExpenses(
          returns,
          getExpenseOfCategory(expenses, "financial expense")
        )}
      />
      <SC.NestedList data={modelOtherExpenses(returns)} />
    </SC.TaxHistoryAccordion>
  );
};

export default RentalTaxViewDetails;
