// Dependencies
import React from "react";

// GraphQL
import {
  useGetPaginatedNonIndividualReportsQuery,
  useGetPaginatedLandlordIncomeSummaryQuery,
} from "graphql/hasura/types-and-hooks";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { CLIENT_NAME } from "graphql/client";

// Component
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import {
  unregisteredColumns,
  noReturnsColumns,
  noIncomeColumns,
  underreportedColumns,
  compliantreportedColumns,
  LandlordIncomeSummaryColumns,
} from "./analysis-tab.schema";

// Assets
import SC from "./analysis-tab.styles";

interface AnalysisComponentProps {
  Rnid: string;
  Tin: string;
}
const AnalysisComponent: React.FC<AnalysisComponentProps> = ({ Rnid, Tin }) => {
  const unregisteredQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: Rnid } },
        { Compliancecategory: { _eq: "NO MATCH" } },
      ],
    },
  };

  const noReturnQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: Rnid } },
        { Compliancecategory: { _eq: "NO RETURN" } },
      ],
    },
  };

  const noIncomeQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: Rnid } },
        { Compliancecategory: { _eq: "NO INCOME" } },
      ],
    },
  };

  const underreportedQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: Rnid } },
        { Compliancecategory: { _eq: "UNDERREPORTED" } },
      ],
    },
  };

  const compliantQueryVariables = {
    where: {
      _and: [
        { Rnid: { _eq: Rnid } },
        { Compliancecategory: { _eq: "COMPLIANT" } },
      ],
    },
  };

  const declaredVsReportedQueryVariables = {
    where: {
      LandlordTin: { _eq: Tin },
    },
  };

  const {
    data: unregisteredData,
    loading: unregisteredLoading,
    error: unregisteredError,
  } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !unregisteredQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(unregisteredQueryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: unregisteredQueryVariables?.where,
      }),
    },
  });

  const {
    data: noReturnData,
    loading: noReturnLoading,
    error: noReturnError,
  } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !noReturnQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(noReturnQueryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: noReturnQueryVariables?.where,
      }),
    },
  });

  const {
    data: noIncomeData,
    loading: noIncomeLoading,
    error: noIncomeError,
  } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !noIncomeQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(noIncomeQueryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: noIncomeQueryVariables?.where,
      }),
    },
  });

  const {
    data: underreportedData,
    loading: underreportedLoading,
    error: underreportedError,
  } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !underreportedQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(underreportedQueryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: underreportedQueryVariables?.where,
      }),
    },
  });

  const {
    data: compliantData,
    loading: compliantLoading,
    error: compliantError,
  } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !compliantQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(compliantQueryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: compliantQueryVariables?.where,
      }),
    },
  });

  const {
    data: declaredVsReportedData,
    loading: declaredVsReportedLoading,
    error: declaredVsReportedError,
  } = useGetPaginatedLandlordIncomeSummaryQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !declaredVsReportedQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(
        declaredVsReportedQueryVariables ?? {}
      ),
      aggregateParams: jsonToStringQueryVariables({
        where: declaredVsReportedQueryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(
    unregisteredError ||
      noReturnError ||
      noIncomeError ||
      underreportedError ||
      compliantError ||
      declaredVsReportedError
  );

  return (
    <SC.Container maxWidth={false}>
      <ProgressIndicator
        open={
          unregisteredLoading ||
          noReturnLoading ||
          noIncomeLoading ||
          underreportedLoading ||
          compliantLoading ||
          declaredVsReportedLoading
        }
      />
      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Compliant Taxpayers"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={compliantreportedColumns}
          data={
            compliantData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non6"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Unregistered Landlords"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={unregisteredColumns}
          data={
            unregisteredData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non5"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with No Returns"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={noReturnsColumns}
          data={
            noReturnData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non4"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with No Income"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={noIncomeColumns}
          data={
            noIncomeData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non3"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Taxpayers with Underreported Income"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={underreportedColumns}
          data={
            underreportedData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ??
            []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non2"
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          isPaginationHidden
          noDataComponent={
            <SC.Typography variant="h6">No Analysis Applicable</SC.Typography>
          }
          title="Landlord Declared vs Tenant Reported"
          actionsOnRight={["fullscreen-expand", "hide/show-columns"]}
          onAction={() => {}}
          columns={LandlordIncomeSummaryColumns}
          data={
            declaredVsReportedData?.rtcs_db_Fnura_LandlordIncomeSummary ?? []
          }
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non1"
        />
      </SC.Card>
    </SC.Container>
  );
};

export default AnalysisComponent;
