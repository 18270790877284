export default [
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [29.8541574, 0.385833],
    },
    properties: {
      name: "Property 1",
      styleUrl: "#style_1",
      styleHash: "-232d130d",
      icon: "https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ",
      Icon: '<a href="https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ" target="_blank">https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ</a>',
      Name: "DWP - Armed Forces Champion for Leicestershire and Northamptonshire",
      "Cobseo Registered?": "No",
      Address: "Adress 1",
      Postcode: "",
      Telephone: "07826 548560",
      Email:
        '<a href="mailto:afc.leic.northants@dwp.gov.uk">afc.leic.northants@dwp.gov.uk</a>',
      Website: "",
      Description:
        "Leicestershire and Northamptonshire Armed Forces Champion for DWP.",
      ALF: "719ea178-26b9-4ca4-a07d-5b2831edaeb3",
      Latitude: "52.2401008605957",
      Longitude: "-0.8910861611366272",
      cid: "52.2401008605957,-0.8910861611366272",
    },
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [33.14650155646976, 0.48200968660663157],
    },
    properties: {
      name: "Property 2",
      styleUrl: "#style_1",
      styleHash: "-232d130d",
      icon: "https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ",
      Icon: '<a href="https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ" target="_blank">https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ</a>',
      Name: "DWP - Armed Forces Champion for Leicestershire and Northamptonshire",
      "Cobseo Registered?": "No",
      Address: "Address 2",
      Postcode: "",
      Telephone: "07826 548560",
      Email:
        '<a href="mailto:afc.leic.northants@dwp.gov.uk">afc.leic.northants@dwp.gov.uk</a>',
      Website: "",
      Description:
        "Leicestershire and Northamptonshire Armed Forces Champion for DWP.",
      ALF: "719ea178-26b9-4ca4-a07d-5b2831edaeb3",
      Latitude: "52.2401008605957",
      Longitude: "-0.8910861611366272",
      cid: "52.2401008605957,-0.8910861611366272",
    },
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [32.5761443, 0.314444],
    },
    properties: {
      name: "Property 3",
      styleUrl: "#style_1",
      styleHash: "-232d130d",
      icon: "https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ",
      Icon: '<a href="https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ" target="_blank">https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ</a>',
      Name: "DWP - Armed Forces Champion for Leicestershire and Northamptonshire",
      "Cobseo Registered?": "No",
      Address: "Address 2",
      Postcode: "",
      Telephone: "07826 548560",
      Email:
        '<a href="mailto:afc.leic.northants@dwp.gov.uk">afc.leic.northants@dwp.gov.uk</a>',
      Website: "",
      Description:
        "Leicestershire and Northamptonshire Armed Forces Champion for DWP.",
      ALF: "719ea178-26b9-4ca4-a07d-5b2831edaeb3",
      Latitude: "52.2401008605957",
      Longitude: "-0.8910861611366272",
      cid: "52.2401008605957,-0.8910861611366272",
    },
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [32.8073774, 1.4595245],
    },
    properties: {
      name: "Property 4",
      styleUrl: "#style_1",
      styleHash: "-232d130d",
      icon: "https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ",
      Icon: '<a href="https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ" target="_blank">https://res.cloudinary.com/rwaves/image/upload/v1/alfresco/tOt8TZV_THGhDXQ5VMqfIQ</a>',
      Name: "DWP - Armed Forces Champion for Leicestershire and Northamptonshire",
      "Cobseo Registered?": "No",
      Address: "Address 2",
      Postcode: "",
      Telephone: "07826 548560",
      Email:
        '<a href="mailto:afc.leic.northants@dwp.gov.uk">afc.leic.northants@dwp.gov.uk</a>',
      Website: "",
      Description:
        "Leicestershire and Northamptonshire Armed Forces Champion for DWP.",
      ALF: "719ea178-26b9-4ca4-a07d-5b2831edaeb3",
      Latitude: "52.2401008605957",
      Longitude: "-0.8910861611366272",
      cid: "52.2401008605957,-0.8910861611366272",
    },
  },
];
