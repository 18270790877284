/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Box, Typography } from "@material-ui/core";

const Container = Box;

const Title = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
}))(Typography);

export default {
  Container,
  Title,
};
