// Dependencies
// Dependencies
import React from "react";

// Styles
import SC from "./note-toolbar.styles";

// Interfaces
export interface NoteToolBarProps {
  id: string;
}

const copyToClipBoard = async (url: string) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(url);
    return;
  }

  // Fallback on http | execCommand is depreciated
  const element = document.createElement("textarea");
  element.value = url;
  document.body.appendChild(element);
  element.select();
  document.execCommand("copy");
  document.body.removeChild(element);
};

const NoteToolbar: React.FC<NoteToolBarProps> = ({ id }) => {
  const url = window.location.href;
  const formatedID = id.replace("#", "");
  const noteURL = id ? `${url}/${formatedID}` : ``;

  return (
    <SC.Toolbar>
      <SC.Title variant="subtitle1">Note</SC.Title>
      {noteURL && (
        <SC.ToolbarActions>
          <SC.ToolBarAction component="span">
            <SC.IconButton
              aria-label="Copy URL"
              onClick={() => copyToClipBoard(noteURL)}
            >
              <SC.FileCopyOutlined />
            </SC.IconButton>
            <SC.ActionText>Copy URL</SC.ActionText>
          </SC.ToolBarAction>
        </SC.ToolbarActions>
      )}
    </SC.Toolbar>
  );
};

export default NoteToolbar;
