/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  NonIndividualCompliancePlusRiskReportFragment,
  LandlordIncomeSummaryFragment,
} from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";
import { Link } from "react-router-dom";

export const unregisteredColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "center",
    },
    {
      Header: "Source",
      accessor: "Source",
      align: "center",
      format: "string",
    },
    {
      Header: "Source Customer Id",
      accessor: "Kccacustomerid",
      align: "center",
      format: "string",
    },
    {
      Header: "Source Entity Legal Name",
      accessor: "Kccaname",
      align: "left",
      format: "string",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "Source Tin",
      accessor: "Tinno",
      align: "center",
      format: "string",
    },
    {
      Header: "Source Mobile",
      accessor: "Mobilenumber",
      align: "right",
      format: "string",
    },
    {
      Header: "Source Email",
      accessor: "Emailid",
      align: "center",
      format: "string",
    },
    {
      Header: "Total No. of Properties",
      accessor: "Numproperties",
      align: "center",
    },
    {
      Header: "Total No. of Rented Properties",
      accessor: "Numrentedproperties",
      align: "center",
    },
    {
      Header: "Sum Rateable Value of Rented Properties",
      accessor: "Sumrateablevalue",
      align: "right",
      format: "currency",
    },
    {
      Header: "Sum of the Adjusted Gross Income",
      accessor: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Rented Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const noReturnsColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "center",
    },
    {
      Header: "Source",
      accessor: "Source",
      align: "center",
      format: "string",
    },
    {
      Header: "Entity Legal Name",
      accessor: "Kccaname",
      align: "left",
      format: "string",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      Header: "Tax Year",
      accessor: "Rtnperiodyear",
      align: "center",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Rented Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const noIncomeColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "center",
    },
    {
      Header: "Source",
      accessor: "Source",
      align: "center",
      format: "string",
    },
    {
      Header: "Entity Legal Name",
      accessor: "Kccaname",
      align: "left",
      format: "string",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Rented Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "center",
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const underreportedColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "center",
    },
    {
      Header: "URA Entity Legal Name",
      accessor: "Kccaname",
      align: "left",
      format: "string",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "center",
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const compliantreportedColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "center",
    },
    {
      Header: "URA Entity Legal Name",
      accessor: "Kccaname",
      align: "left",
      format: "string",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "center",
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const LandlordIncomeSummaryColumns: TableColumn<LandlordIncomeSummaryFragment>[] =
  [
    {
      Header: "Landlord TIN",
      accessor: "LandlordTin",
      align: "center",
    },
    {
      Header: "Landlord Taxpayer Name",
      accessor: "LandlordTaxpayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Tax Year",
      accessor: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      Header: "Return No.",
      accessor: "ReturnNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Return Period From",
      accessor: "ReturnPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period To",
      accessor: "ReturnPeriodTo",
      align: "center",
      format: "date",
    },
    {
      Header: "Rent Income Reported by Tenant",
      accessor: "RentReportedByTenants",
      align: "right",
      Cell: ({
        value,
        row: {
          values: { LandlordTin, TaxYear },
        },
        cell,
      }) => {
        return (
          <Link
            to={`analysis/tenant/${LandlordTin}/${TaxYear}/detail`}
            color="inherit"
          >
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      Header: "Rent Income Reported on Tax Form",
      accessor: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Rent Income Declared by Landlord Details",
      accessor: "RentDeclaredByLandlord",
      align: "right",
      Cell: ({
        value,
        row: {
          values: { ReturnNo },
        },
        cell,
      }) => {
        return (
          <Link to={`analysis/landlord/${ReturnNo}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      Header: "rMATCH Score",
      accessor: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      Header: "Difference - Tenant Report vs Landlord Declared",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
    {
      Header: "Diff %",
      accessor: "DifferencePercentage",
      align: "center",
      format: "percent",
    },
    {
      Header: "Landlord TIN Source",
      accessor: "LandlordTinSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Return No. Source",
      accessor: "ReturnNoSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
  ];
