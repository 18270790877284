// Dependencies
import React from "react";

// Components
import BannerGraphic from "components/banner-graphic/banner-graphic.component";
import Navigation from "components/navigation/navigation.component";
import AdministrationMenu from "components/administration-menu/administration-menu.component";
// TODO: To be added if search feature is put in to use in the feature
// import GeneralSearch from "components/general-search/general-search.component";
import UserMenu from "components/user-menu/user-menu.component";
import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import SC from "./banner-navigation.styles";

const BannerNavigation: React.FC = () => (
  <SC.BannerNavigation>
    <BannerGraphic />
    <Navigation />
    <AppPermissionValidator appPermission={APP_PERMISSION.ADMIN}>
      <AdministrationMenu />
    </AppPermissionValidator>
    <UserMenu />
  </SC.BannerNavigation>
);

export default BannerNavigation;
