/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useMemo } from "react";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import { FilterCondition } from "components/table-filter/table-filter.component";
import FilterComponent from "./filter.component";
import QueryBuilder from "./queryBuilder";
import { Parent, PascalCaseFormat } from "./universal.schema";
import SC from "./universal-filter.styles";

export interface ColumnsProps {
  index: number;
  label: string;
  value: string;
  type: string;
}

export interface UniversalFilterProps {
  resetSignal?: boolean;
  label: string;
  columns: ColumnsProps[];
  onFilterSubmit?: ({ condition }: UniversalFilterResponse) => void;
  defaultConditions?: FilterCondition[];
}

export interface UniversalFilterResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: { [x: string]: { [x: string]: string | number | boolean } | any };
}

export const UniversalFilter = ({
  resetSignal,
  label,
  columns,
  onFilterSubmit,
  defaultConditions,
}: UniversalFilterProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [condition, setCondition]: any = React.useState([{}]);

  const onLabelClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setCondition([{}]);
  };

  // check if filter values are entered.
  const validFilter = useMemo(() => {
    let res = true;
    for (let i = 0; i < condition.length; i += 1) {
      const filter = condition[i];
      if (filter.column && filter.queries) {
        if (filter.queries !== "isnull" && filter.queries !== "notnull") {
          if (filter.value === "") {
            res = false;
            break;
          }
        }
      } else {
        res = false;
        break;
      }
    }
    return res;
  }, [condition]);

  const onSubmit = () => {
    const filters = { condition: {} };
    let isSubmit = true;
    for (let i = 0; i < condition.length; i += 1) {
      if (!_.isEmpty(condition[i])) {
        const cond: any = condition[i];
        const filter = {
          ...QueryBuilder({
            column: cond.column,
            query: Parent[cond.queries],
            value: cond.value,
            selectedType: cond.selectedType,
            join: cond.operator,
          }),
        };
        let getOperator = null;
        if (i === 0) {
          const newFilter = { ...filter };
          // @ts-ignore
          delete newFilter.operator;
          // eslint-disable-next-line prefer-destructuring
          getOperator = Object.keys(newFilter[Object.keys(newFilter)[0]])[0];
          if (getOperator === "_eq" && cond.selectedType === "string") {
            filters.condition = {
              _and: [{ _or: [newFilter, PascalCaseFormat(newFilter)] }],
            };
          } else {
            filters.condition = { _and: [newFilter] };
          }
        } else {
          const key = condition[i - 1].operator;
          if (key === "default") {
            isSubmit = false;
            break;
          }
          if (key in filters.condition) {
            // @ts-ignore
            if (Array.isArray(filters.condition[key])) {
              const newFilter = { ...filter };
              // @ts-ignore
              delete newFilter.operator;
              // eslint-disable-next-line prefer-destructuring
              getOperator = Object.keys(
                newFilter[Object.keys(newFilter)[0]]
              )[0];
              if (getOperator === "_eq" && cond.selectedType === "string") {
                // @ts-ignore
                filters.condition[key] = [
                  // @ts-ignore
                  ...filters.condition[key],
                  { _or: [newFilter, PascalCaseFormat(newFilter)] },
                ];
              } else {
                // @ts-ignore
                filters.condition[key] = [...filters.condition[key], newFilter];
              }
            } else {
              const newFilter = { ...filter };
              // @ts-ignore
              delete newFilter.operator;
              // eslint-disable-next-line prefer-destructuring
              getOperator = Object.keys(
                newFilter[Object.keys(newFilter)[0]]
              )[0];

              if (getOperator === "_eq" && cond.selectedType === "string") {
                // @ts-ignore
                filters.condition[key] = [
                  // @ts-ignore
                  filters.condition[key],
                  { _or: [newFilter, PascalCaseFormat(newFilter)] },
                ];
              } else {
                // @ts-ignore
                filters.condition[key] = [filters.condition[key], newFilter];
              }
            }
          } else {
            const newFilter = { ...filter };
            // @ts-ignore
            delete newFilter.operator;

            // eslint-disable-next-line prefer-destructuring
            getOperator = Object.keys(newFilter[Object.keys(newFilter)[0]])[0];
            if (getOperator === "_eq" && cond.selectedType === "string") {
              // @ts-ignore
              filters.condition[key] = [
                { _or: [newFilter, PascalCaseFormat(newFilter)] },
              ];
            } else {
              // @ts-ignore
              filters.condition[key] = newFilter;
            }
          }
        }
      }
    }

    if (onFilterSubmit && isSubmit && !_.isEmpty(filters.condition)) {
      if (defaultConditions) {
        // @ts-ignore
        if (filters.condition._and) {
          // @ts-ignore
          if (Array.isArray(filters.condition._and)) {
            filters.condition = {
              ...filters.condition,
              // @ts-ignore
              _and: [...filters.condition._and, ...defaultConditions],
            };
          } else {
            // @ts-ignore
            const newFilterCondition = { ...filters.condition };
            // @ts-ignore
            if (newFilterCondition._or) {
              // @ts-ignore
              delete newFilterCondition._or;
            }

            filters.condition = {
              // @ts-ignore
              _and: [
                // @ts-ignore
                newFilterCondition._and,
                ...defaultConditions,
              ],
              // @ts-ignore
              _or: filters.condition._or ? filters.condition._or : [],
            };
          }
        } else {
          // @ts-ignore
          const newFilterCondition = { ...filters.condition };
          // @ts-ignore
          if (newFilterCondition._or) {
            // @ts-ignore
            delete newFilterCondition._or;
          }
          filters.condition = {
            // @ts-ignore
            _and: [newFilterCondition, ...defaultConditions],
            // @ts-ignore
            _or: filters.condition._or ? filters.condition._or : [],
          };
        }
      }
      onFilterSubmit(filters);
      setOpen(false);
    }
  };

  const appendFilter = () => {
    const newCondition = [...condition];
    newCondition.push({});
    setCondition(newCondition);
  };

  const onRemoveFilter = (index: number) => {
    const newCondition = [...condition];
    newCondition.splice(index, 1);
    setCondition(newCondition);
  };

  const onSetColumn = (
    column: string | [string, string],
    value: string | [string, string],
    index: number
  ) => {
    const newCondition = [...condition];
    const defaults = { queries: "default", value: "", operator: "default" };
    let values = {};
    if (Array.isArray(column)) {
      values = { [column[0]]: value[0], [column[1]]: value[1] };
    } else {
      values = { [column]: value };
    }
    newCondition[index] = { ...newCondition[index], ...values };
    if (Array.isArray(column)) {
      if (column[0] === "column") {
        newCondition[index] = { ...newCondition[index], ...defaults };
      }
    } else if (column === "column") {
      newCondition[index] = { ...newCondition[index], ...defaults };
    }
    // console.log(newCondition);
    setCondition(newCondition);
  };

  return (
    <SC.Container>
      <SC.Button onClick={onLabelClick}> {label} </SC.Button>
      <SC.Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
        <SC.DialogTitle> {label} </SC.DialogTitle>
        <SC.DialogContent>
          {condition.map((cond: any, index: any) => {
            return (
              <FilterComponent
                key={index}
                column={cond.column ?? "default"}
                queries={cond.queries ?? "default"}
                value={cond.value ?? ""}
                join={cond.operator ?? "default"}
                setColumn={(
                  column: string | [string, string],
                  value: string | [string, string]
                ) => onSetColumn(column, value, index)}
                resetSignal={resetSignal}
                onRemoveFilter={() => onRemoveFilter(index)}
                firstFilter={index === 0}
                lastFilter={index === condition.length - 1}
                columns={columns}
              />
            );
          })}
          <SC.Box>
            <SC.TextButton
              disabled={condition.length > 4}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={appendFilter}
            >
              Add Filter Condition
            </SC.TextButton>
          </SC.Box>
        </SC.DialogContent>
        <SC.DialogActions>
          <SC.CancelButton onClick={handleClose}>Cancel </SC.CancelButton>
          <SC.Button disabled={!validFilter} autoFocus onClick={onSubmit}>
            Submit
          </SC.Button>
        </SC.DialogActions>
      </SC.Dialog>
    </SC.Container>
  );
};

export default UniversalFilter;
