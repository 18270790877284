// Dependencies
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import usePrevious from "@react-hook/previous";

// Pages
import MainPage from "pages/main/main.component";
import LoginPage from "pages/login/login.component";
import ResetPassword from "pages/reset-password/reset-password.component";
import ForgotPassword from "pages/forgot-password/forgot-password.component";
import EmailVerification from "pages/email-verification/email-verification.component";

// Components
import ErrorBoundary from "components/error-boundary/error-boundary.component";

// Graphql
import auth from "graphql/authentication";

// Assets
import SC from "./app.styles";

const App: React.FC = () => {
  const { isLoggedIn } = auth.useAuthenticatioData();

  // Save the URL you copied and pasted in a new tab or window in the browser, or in case you have reloaded the page.
  const previousUrl = usePrevious(window.location.pathname);
  // If the URL you copied, pasted or reloaded is the same as "/" or "/login" it will redirect you to the home page after logging in,
  // otherwise it will redirect you to the page you want to access before logging in.
  const url =
    previousUrl === "/" || previousUrl === "/login" ? "/home" : previousUrl;

  return (
    <SC.App>
      <ErrorBoundary>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              {isLoggedIn ? <Redirect to={`${url}`} /> : <LoginPage />}
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/email-verification">
              <EmailVerification />
            </Route>
            <Route path="/">
              {isLoggedIn ? <MainPage /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </BrowserRouter>
      </ErrorBoundary>
    </SC.App>
  );
};

export default App;
