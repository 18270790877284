/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from "react";

import { useGetReportLazyQuery } from "graphql/types-and-hooks";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Components
import CardContainer from "components/report-container/report-container.component";
import ReportCard from "components/report-card/report-card.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
// Styles
import DialogForm from "components/dialog";
import { handleExportDataToZIP } from "components/table/table.actions";
import SC from "./main.styles";

//  Mock data
import Data from "./report-card-shema";

export const ReportsPage = () => {
  const [open, setOpen] = React.useState(false);
  const [Rnid, setRnid] = React.useState("");

  const [GetReportFn, { loading, data }] = useGetReportLazyQuery({
    variables: { rnid: Rnid },
  });

  const handleGenerateReport = (rnid: string) => {
    setRnid(rnid);
    GetReportFn({
      variables: {
        rnid,
      },
    });
  };

  useEffect(() => {
    if (Rnid !== "" && data?.GetReport?.data) {
      setOpen(false);
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
      const reportName = `rTCS-${Rnid}-${formattedDate}`;
      handleExportDataToZIP(data.GetReport.data, reportName);
      setRnid("");
    }
  }, [data, Rnid]);

  const onHandleClose = () => {
    setOpen(false);
  };

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <ProgressIndicator open={loading} />
      <SC.Container container justify="center" spacing={2}>
        {Data.map(({ title, reports }: any) => (
          <CardContainer key={title} title={title}>
            {reports?.map((report: any) => (
              <ReportCard
                key={`${title}: ${report.title}`}
                title={report.title}
                nickname={report.nickname}
                type={report.type}
                content={report.content}
                subtitle={report.subtitles}
                otherText={report.otherText}
                onClickReport={() => setOpen(true)}
              />
            ))}
          </CardContainer>
        ))}
        <DialogForm
          open={open}
          handleClose={onHandleClose}
          onGenerateReport={handleGenerateReport}
        />
      </SC.Container>
    </SC.Box>
  );
};

export default ReportsPage;
