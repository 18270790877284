/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Grid as GridBase,
  Box as BoxBase,
  Typography,
} from "@material-ui/core";

const Container = withStyles({
  root: {
    flexGrow: 1,
    paddingLeft: "1.14rem",
  },
})(GridBase);

// const WrapperBox = withStyles(() => ({
//   root: {
//     overflow: "scroll",
//     height: "90vh",
//   },
// }))(BoxBase);

const Box = BoxBase;

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
}))(Typography);

export default {
  Container,
  Title,
  Box,
  // WrapperBox,
};
