import { Maybe } from "graphql/hasura/types-and-hooks";
import React, { useState, ChangeEvent } from "react";
import SC from "./tax-history-accordion.styles";

type TaxHistoryAccordionSubTitle = {
  label: string;
  value: Maybe<string> | undefined;
};

export interface TaxHistoryAccordionProps {
  title: string;
  subtitles?: TaxHistoryAccordionSubTitle[];
  children: JSX.Element[];
}

const createSlug = (title: string) => {
  return title
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/&/g, "-y-");
};

const TaxHistoryAccordion: React.FC<TaxHistoryAccordionProps> = ({
  title,
  subtitles,
  children,
}) => {
  const slug = createSlug(title);

  const [expanded, setExpanded] = useState<string | false>(slug);

  const handleChange =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <SC.TaxHistoryViewContainer>
      <SC.TaxHistoryViewAccordion
        expanded={expanded === slug}
        onChange={handleChange(slug)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <SC.TaxHistoryItemHeader
          expandIcon={<SC.ExpandMore />}
          aria-controls={`${slug}-content`}
          id={`${slug}-header`}
        >
          <SC.Grid container justify="space-between">
            <SC.Grid item xs={12}>
              <SC.AccordionHeaderTypography variant="h6">
                {title}
              </SC.AccordionHeaderTypography>
            </SC.Grid>
            {subtitles &&
              subtitles.map((item) => (
                <SC.Grid item xs={4} key={item.label}>
                  <SC.AccordionHeaderTypography variant="h6">
                    {item.label}
                    <SC.Box component="span" m={1} fontWeight="normal">
                      {item.value}
                    </SC.Box>
                  </SC.AccordionHeaderTypography>
                </SC.Grid>
              ))}
          </SC.Grid>
        </SC.TaxHistoryItemHeader>
        <SC.TaxHistoryContent>
          <SC.Container>{children.map((child) => child)}</SC.Container>
        </SC.TaxHistoryContent>
      </SC.TaxHistoryViewAccordion>
    </SC.TaxHistoryViewContainer>
  );
};

export default TaxHistoryAccordion;
