/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraNonIndividualsAssociatedBusinessFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTableSchema = Omit<
  UraNonIndividualsAssociatedBusinessFragment,
  "__typename"
>;

// @ts-ignore
export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  TaxPayerName: "string",
  AssociateTin: "string",
  AssociatedNonIndividual: "string",
  MainActivity: "string",
  ActvtyDesc: "string",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTableSchema
    | Accessor<NonIndividualTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<NonIndividualTableSchema>[] = [
  {
    Header: "TIN No.",
    accessor: "TinNo",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { TinNo },
      },
    }) => {
      return (
        <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
    sort: true,
  },
  {
    Header: "Taxpayer Name",
    accessor: "TaxPayerName",
    align: "left",
    format: "string",
  },
  {
    Header: "Associate TIN",
    accessor: "AssociateTin",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { AssociateTin },
      },
    }) => {
      return (
        <Link
          to={`/non-individuals/${AssociateTin}/identification`}
          color="inherit"
        >
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Associated Business",
    accessor: "AssociatedNonIndividual",
    align: "left",
    format: "string",
  },
  {
    Header: "Main Activity",
    accessor: "MainActivity",
    align: "left",
    format: "string",
  },
  {
    Header: "Activity Description",
    accessor: "ActvtyDesc",
    align: "left",
    format: "string",
  },
];
