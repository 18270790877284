import React from "react";
import { useWindowHeight } from "@react-hook/window-size";

import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";

import SC from "./map-table-container.styles";

interface MapTableContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any;
  leftPanel?: JSX.Element;
  persistenceId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paginationControlled?: any;
  initialRowsPerPage?: number;
  pageIndexResetSignal?: boolean | undefined;
  stickyHeader?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportData?: (column: any) => void;
}

const onActionHandler = (action: TableToolbarAction) => {
  // eslint-disable-next-line no-console
  console.log(action);
};

const MapTableContainer: React.FC<MapTableContainerProps> = ({
  properties,
  columns,
  persistenceId,
  leftPanel,
  paginationControlled,
  initialRowsPerPage,
  pageIndexResetSignal,
  stickyHeader,
  exportData,
}) => {
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();

  const mainContainerPositionY = mainContainerPosition?.y ?? 0;
  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;

  return (
    <SC.Container
      ref={mainContainerRef}
      maxHeight={containerHeight}
      maxWidth={false}
    >
      <SC.Table
        title="Properties"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "export-to-excel-sheet/csv",
        ]}
        onAction={onActionHandler}
        columns={columns}
        data={properties}
        persistenceId={persistenceId}
        paginationControlled={paginationControlled}
        initialRowsPerPage={initialRowsPerPage}
        pageIndexResetSignal={pageIndexResetSignal}
        stickyHeader={stickyHeader}
        leftPanel={leftPanel}
        exportData={exportData}
      />
    </SC.Container>
  );
};

export default MapTableContainer;
