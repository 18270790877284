/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Typography, Grid } from "@material-ui/core";

const LabelText = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
    textAlign: "right",
    fontWeight: 600,
    fontSize: theme.labelKeyValue.label.fontSize,
  },
}))(Typography);

const LabelValue = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
    textAlign: "left",
    fontWeight: 100,
    fontSize: theme.labelKeyValue.value.fontSize,
  },
}))(Typography);

export default {
  LabelText,
  LabelValue,
  Grid,
};
