/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import NoteAuthor from "../note-author/note-author.component";
import NoteToolbar from "../note-toolbar/note-toolbar.component";

const AttachmentIconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.notes.attachments.iconButton.padding,
    marginRight: theme.notes.attachments.iconButton.marginRight,
    color: theme.palette.primary.dark,
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      fontSize: theme.notes.attachments.iconButton.fontSize,
    },
  },
}))(IconButton);

const NoteContainer = withStyles((theme: any) => ({
  root: {
    marginBottom: theme.notes.container.marginBottom,
  },
}))(Card);

const NoteContent = withStyles((theme: any) => ({
  root: {
    paddingTop: theme.notes.container.content.paddingTop,
    paddingBottom: theme.notes.container.content.paddingBottom,
    paddingRight: theme.notes.container.content.paddingRight,
    paddingLeft: theme.notes.container.content.paddingLeft,
    "&:last-child": {
      paddingBottom: theme.notes.container.content.paddingBottom,
    },
  },
}))(CardContent);

const NoteTimestamp = withStyles((theme: any) => ({
  root: {
    marginTop: theme.notes.container.content.timestamp.marginTop,
  },
}))(Typography);

const NoteBody = withStyles((theme: any) => ({
  root: {
    marginTop: 0,
    "& p": {
      marginBottom: 0,
      marginTop: theme.notes.container.content.body.paddingTop,
      fontSize: theme.notes.body.fontSize,
    },
  },
}))(Box);

export default {
  NoteContainer,
  NoteToolbar,
  NoteContent,
  NoteAuthor,
  NoteTimestamp,
  NoteBody,
  AttachmentIconButton,
  AttachFile,
  Typography,
  Box,
};
