/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";

import SC from "./balance-sheet-list.styles";

export const FORMAT_VALUE_OPTIONS = {
  number: "number",
  currency: "currency",
  date: "date",
  dateTime: "dateTime",
  percent: "percent",
  area: "area",
  phone: "phone",
  coordinate: "coordinate",
  string: "string",
};

type FormatValueType = keyof typeof FORMAT_VALUE_OPTIONS;

export interface StructureInterface {
  label?: string;
  isBold?: boolean;
  level: number;
  value?: string | number | Date | undefined | Maybe<string>;
  value_highlighted?: boolean;
  format: FormatValueType;
  isMega?: boolean;
  emptyRowAbove?: boolean;
}

interface InputProps {
  structure: StructureInterface[];
}

const BalanceSheetList: React.FC<InputProps> = ({ structure }) => {
  return (
    <SC.List aria-label="secondary">
      {structure.map(
        ({
          level,
          label,
          isBold,
          format,
          value,
          isMega = false,
          emptyRowAbove = false,
        }: StructureInterface) => (
          <>
            {emptyRowAbove && (
              <SC.ListItem level={level} has_items={false}>
                <p />
              </SC.ListItem>
            )}
            <SC.ListItem level={level} has_items={false}>
              <SC.ListItemText
                isMega={isMega}
                isTitle={isBold}
                primary={label}
              />
              <SC.ListItemSecondaryAction>
                <SC.ListItemText
                  isTitle={isBold}
                  primary={
                    <SC.FormatedValue value={value ?? 0} format={format} />
                  }
                />
              </SC.ListItemSecondaryAction>
            </SC.ListItem>
          </>
        )
      )}
    </SC.List>
  );
};

export default BalanceSheetList;
