// Dependencies
import React from "react";

// Graphql
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs,
  Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column,
  useGetMaxMinRptRentalTenantPaymentsQuery,
  useGetPaginatedRptRentalTenantPaymentsQuery,
  useRptRentalTenantPaymentsFilterQuery,
} from "graphql/hasura/types-and-hooks";

// Components
import {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Data export
import { useExportData } from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import {
  columns as reportColumns,
  getAccessorType,
} from "./tenant-rental-payments.schema";

// Styles
import SC from "./tenant-rental-payments.style";

const TABLE_NAME = "rtcs_db_FnRPT_RentalTenantPayments";

const TenantRentalPayments: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs | undefined
  >(undefined);

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedRptRentalTenantPaymentsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const {
    data: maxMinData,
    loading: maxMinLoading,
    error: maxMinError,
  } = useGetMaxMinRptRentalTenantPaymentsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  // Data export
  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError || maxMinError);

  // Table Columns
  const columns = React.useMemo(() => reportColumns, []);

  // Pagination
  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordName,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_RentalTenantPayments_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: paymentsFilterFn } = useRptRentalTenantPaymentsFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column,
              ],
            };
          const paymentsFilterData = await paymentsFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            paymentsFilterData.data?.rtcs_db_FnRPT_RentalTenantPayments.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [paymentsFilterFn]
  );

  const newObj = aggregateArrayToObject(
    maxMinData?.rtcs_db_FnRPT_RentalTenantPayments_aggregatecm
  );

  const minRentPaid = newObj?.['min("RentPaid")'];
  const maxRentPaid = newObj?.['max("RentPaid")'];

  // filterInpute
  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Landlord Name",
        columnName:
          Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Landlord Tin",
        columnName:
          Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordTin,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Tenant Name",
        columnName:
          Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.TenantName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Tenant Tin",
        columnName: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.TenantTin,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Premises Location",
        columnName:
          Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.PremiseLocation,
        fetchSuggestions,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Rent Paid",
        min: minRentPaid,
        max: maxRentPaid,
        columnName: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.RentPaid,
      },
    ];
  }, [fetchSuggestions, minRentPaid, maxRentPaid]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const universalColumns: ColumnsProps[] = React.useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <SC.ProgressIndicator open={loading || exportLoading || maxMinLoading} />
      <SC.Breadcrumb />
      <SC.Table
        title="Tenant Reported Rent Paid"
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        columns={columns}
        data={data?.rtcs_db_FnRPT_RentalTenantPayments ?? []}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        stickyHeader
        persistenceId="0a848f5c-98fc-47cc-b7ff-1f73286e732f"
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
    </SC.Box>
  );
};

export default TenantRentalPayments;
