/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
// Dependencies
import React from "react";

// Components
import { Virtuoso } from "react-virtuoso";
import _ from "lodash";
import LayerHeading from "./LayerHeading";
import ControlledListItem from "./ControlledListItem";
import SearchControl from "./SearchControl";

// Shapes & Types
import { layerShape, featureShape } from "../propTypesShapes";

// Assets
import "./OrganizationsList.css";
import SC from "./styles";

// Utils
import { isFirstRender } from "../../shared/utils/data";
import { isDefined } from "../../shared/utils/is";

class OrganizationsList extends React.PureComponent {
  /* Not use withRouter and pass the location property due to
     https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/
     react-router/docs/guides/blocked-updates.md */
  static propTypes = {
    currentLayer: layerShape,
    currentFeature: featureShape,
  };

  constructor(props) {
    super(props);
    const { currentLayer, currentFeature } = props;
    let firstTierStatus = null;
    let hasSelectedAnOrganization = false;
    const newCurrentFeature = null;
    this.listRef = React.createRef();

    if (currentLayer && !isFirstRender(currentLayer.dataLayer)) {
      const { features } = currentLayer.dataLayer;
      firstTierStatus = {};

      features.forEach((organization) => {
        const selected =
          isDefined(currentFeature) && organization.id === currentFeature.id;
        hasSelectedAnOrganization = hasSelectedAnOrganization || selected;

        firstTierStatus[organization.id] = {
          hasDetails: true,
          expanded: false,
          selected,
        };
      });
    }

    this.state = {
      firstTierStatus,
      newCurrentFeature,
      newCurrentLayer: [],
      isSearch: false,
      searchItems: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { currentFeature, currentLayer, route } = props;
    const { searchItems } = state;
    let featureIndex = 0;

    const updatedCurrentLayer = currentLayer.dataLayer.features.map(
      (property) => {
        return property;
      }
    );

    if (currentFeature) {
      featureIndex = _.findIndex(
        updatedCurrentLayer,
        // eslint-disable-next-line no-return-assign
        (arrayItem) => arrayItem.id === currentFeature.id
      );
    }

    const swapArrayLocs = (arr, index1, index2) => {
      [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
      return arr;
    };

    return {
      newCurrentFeature: currentFeature,
      newCurrentLayer:
        currentFeature && currentFeature.scroll
          ? {
              dataLayer: {
                features: swapArrayLocs(updatedCurrentLayer, featureIndex, 0),
              },
            }
          : currentLayer,
      toggleElement: false,
      searchItems: route !== "search" ? [] : searchItems,
    };
  }

  componentDidUpdate() {
    const { currentFeature } = this.state;
    if (currentFeature && currentFeature.scroll) {
      this.listRef.current.scrollToIndex(0);
    }
  }

  selectOrganization(organizationId) {
    const { firstTierStatus } = this.state;
    const newFirstTierStatus = { ...firstTierStatus };
    let hasSelectedAnOrganization = false;

    Object.keys(newFirstTierStatus).forEach((orgId) => {
      const selected = isDefined(organizationId) && orgId === organizationId;
      hasSelectedAnOrganization = hasSelectedAnOrganization || selected;

      newFirstTierStatus[orgId] = {
        ...firstTierStatus[orgId],
        selected,
        expanded: false,
      };
    });

    this.setState({
      firstTierStatus: newFirstTierStatus,
    });
  }

  handleFirstTierOnClick = (index, propertyId) => {
    const { newCurrentLayer: currentLayer } = this.state;
    this.listRef.current.scrollToItem(index, "start");
    const feature = currentLayer.dataLayer.features.find(
      (f) => f.id === propertyId
    );
    this.selectOrganization(propertyId);
    this.props.setCurrentFeature(feature);
    this.setState({
      newCurrentFeature: feature,
    });
  };

  filterSearchResults = (search) => {
    if (search !== "") {
      this.setState({ isSearch: true });
    } else {
      this.setState({ isSearch: false });
      return;
    }
    const { newCurrentLayer: currentLayer } = this.state;
    const dataLayer = currentLayer ? currentLayer.dataLayer : null;
    if (dataLayer) {
      const properties = dataLayer.features;
      const results = properties.filter(
        (item) =>
          item.properties.name.toLowerCase().indexOf(search.toLowerCase()) !==
          -1
      );
      this.setState({ searchItems: results });
    }
  };

  render() {
    const { route } = this.props;
    const {
      searchItems,
      isSearch,
      newCurrentFeature,
      newCurrentLayer: currentLayer,
    } = this.state;
    const dataLayer = currentLayer ? currentLayer.dataLayer : null;
    const showSearchControl = route === "search";

    return (
      <SC.Box style={{ height: "75vh" }}>
        <LayerHeading
          resultsCount={
            isSearch && showSearchControl
              ? searchItems.length > 0
                ? searchItems.length
                : dataLayer.features.length
              : dataLayer.features.length
          }
        />
        {showSearchControl && (
          <SearchControl onSearchProperty={this.filterSearchResults} />
        )}

        {dataLayer && (
          <Virtuoso
            ref={this.listRef}
            data={
              isSearch && showSearchControl
                ? searchItems.length > 0
                  ? searchItems
                  : dataLayer.features
                : dataLayer.features
            }
            itemContent={(index, property) => (
              <ControlledListItem
                index={index}
                property={property}
                currentFeature={newCurrentFeature}
                onSetCurrentFeature={(feature, propertyIndex) => {
                  this.listRef.current.scrollToIndex({
                    index: propertyIndex,
                    align: "start",
                    behavior: "auto",
                  });
                  this.props.setCurrentFeature(feature);
                  this.setState({ newCurrentFeature: feature });
                }}
              />
            )}
          />
        )}
      </SC.Box>
    );
  }
}

export default OrganizationsList;
