/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Switch, useRouteMatch, RouteProps } from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs,
  useKccaPropertiesQuery,
} from "graphql/hasura/types-and-hooks";
import { KccaPropertyQuery_KccaProperty_ElementKey } from "graphql/hasura/rtcs.types";
// Utils
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// Components
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import { formatNumber } from "../../../utils/math";
import ValuationTab from "../valuation-tab/valuation-tab.component";
import UtilitiesTab from "../utilities-tab/utilities-tab.component";
import DetailsTab from "../details-tab/details-tab.component";
import OwnerTab from "../owner-tab/owner-tab.component";
import PropertyNotes from "../notes-tab/notes-tab.component";

// Styles
import SC from "./properties-tab.styles";

export interface IndividualTabRouteParams {
  repId: string;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rMatchdata: any;
}

interface KccaProperties {
  label: string;
  accessor: KccaPropertyQuery_KccaProperty_ElementKey;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PropertiesTabPage = ({ rMatchdata }: Props) => {
  const { path } = useRouteMatch();
  const pathArr = path.split("/");
  pathArr.pop();

  const appPermissionValidator = useAppPermissionValidator();

  const camvId = rMatchdata.CamvId || "";
  const Rnid = rMatchdata.Rnid || "";
  const PropertyNo = rMatchdata.PropertyNo || "";
  const PropertyType = rMatchdata.PropertyTypeId || "";
  const customerId = rMatchdata.CustomerId || "";
  const NwscReference = rMatchdata.NwscCustomerId;

  const propertyAddressGroup: KccaProperties[] = [
    // RNID does not exist in the KccaPropertiesFragment
    // TODO: Remove the masking when RNID has been included in the fragment.
    {
      label: "RNID",
      accessor: "Rnid" as KccaPropertyQuery_KccaProperty_ElementKey,
    },
    { label: "Property No.", accessor: "PropertyNo" },
    { label: "Plot No.", accessor: "PlotNo" },
    { label: "Flat No.", accessor: "FlatNo" },
    { label: "House No.", accessor: "HouseNo" },
    { label: "Block No.", accessor: "BlockNo" },
  ];
  const locationGroup: KccaProperties[] = [
    { label: "Street", accessor: "StreetId" },
    { label: "Address", accessor: "Address" },
    { label: "Village", accessor: "Village" },
    { label: "Parish", accessor: "Parish" },
    { label: "Division", accessor: "Division" },
  ];
  const valuationGroup: KccaProperties[] = [
    { label: "Property Rental Status", accessor: "PropertyRentedStatus" },
    { label: "Gross Value", accessor: "GrossValue", format: "currency" },
    { label: "Rateable Value", accessor: "RateableValue", format: "currency" },
    {
      label: "Current Rateable Value",
      accessor: "CurrentRateableValue",
      format: "currency",
    },
  ];

  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs =
    {
      where: camvId ? { CamvId: { _eq: camvId } } : { Rnid: { _eq: Rnid } },
      limit: 1,
    };

  const { data, error } = useKccaPropertiesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !camvId && !Rnid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const handleError = useAppErrorHandler(error);

  // In order to avoid the warning raised in the browser inspector's console:
  // "Cannot update a component (ErrorBoundary) while rendering a different component (IndividualTabPage)",
  // we need to call handleError inside of useEffect.
  React.useEffect(() => {
    if (data && data.rtcs_db_FnRPT_KccaPropertyRegistrationData.length === 0) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Invalid Search Params`
        )
      );
    }
  }, [data, handleError]);

  if (!data || data.rtcs_db_FnRPT_KccaPropertyRegistrationData.length === 0) {
    return null;
  }

  const propertiesHeader = [
    {
      index: 0,
      label: data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0].Address ?? "",
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: Rnid },
    { index: 3, label: "CustomerId", type: "bold" },
    { index: 4, label: customerId },
  ];

  const headerContent = [
    {
      index: 0,
      title: "Property Address",
      data: [
        ...propertyAddressGroup.map(({ accessor, label }, index) => {
          // eslint-disable-next-line no-return-assign
          return {
            label,
            index,
            value:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-nested-ternary
              accessor === "Rnid"
                ? Rnid
                : accessor === "PropertyNo"
                ? PropertyNo
                : data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0][
                    accessor
                  ] || "",
          };
        }),
      ],
    },

    {
      index: 1,
      title: "Location",
      data: [
        ...locationGroup.map(({ accessor, label }, index) => {
          return {
            label,
            index,
            value:
              data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0][accessor],
          };
        }),
      ],
    },

    {
      index: 2,
      title: "Valuation ",
      data: [
        ...valuationGroup.map(({ accessor, label, format }, index) => {
          return {
            label,
            index,
            value:
              accessor === "GrossValue"
                ? formatNumber(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0][
                      accessor
                    ]
                  )
                : data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0][
                    accessor
                  ] || "",
            format,
          };
        }),
      ],
    },
  ];
  const tabsData = [
    { label: "Details", path: "details", value: 0 },
    { label: "Owner", path: "owner", value: 1 },
    { label: "Utilities", path: "utilities", value: 2 },
    { label: "Valuation", path: "valuation", value: 3 },
    { label: "Field Data", path: "field-data", value: 4 },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let CamvId: any = null;
  if (data.rtcs_db_FnRPT_KccaPropertyRegistrationData.length > 0) {
    CamvId = data.rtcs_db_FnRPT_KccaPropertyRegistrationData[0].CamvId ?? "";
  }

  const routes: RouteProps[] = [
    {
      path: `${path}utilities`,
      exact: true,
      render: () => <UtilitiesTab reference={NwscReference} />,
    },
    {
      path: `${path}valuation`,
      exact: true,
      render: () => <ValuationTab Rnid={Rnid} />,
    },
    {
      path: `${path}details`,
      exact: true,
      render: () => (
        <DetailsTab
          camvId={camvId}
          Rnid={Rnid}
          PropertyNo={PropertyNo}
          PropertyType={PropertyType}
        />
      ),
    },
    {
      path: `${path}owner`,
      exact: true,
      render: () => <OwnerTab camvId={CamvId} propertyNo={PropertyNo} />,
    },
  ];

  if (appPermissionValidator?.(APP_PERMISSION.PROPERTIES_VIEW_NOTES)) {
    tabsData.push({ label: "Notes", path: "notes", value: 5 });
    routes.push({
      path: `${path}notes/:noteID?`,
      strict: true,
      render: () => <PropertyNotes />,
    });
  }

  routes.push({
    render: () => <>Content Not Found</>,
  });

  const BlockNo = data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0].BlockNo;
  const PlotNo = data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0].PlotNo;
  const StreetId = data?.rtcs_db_FnRPT_KccaPropertyRegistrationData[0].StreetId;

  return (
    <SC.Container>
      <SC.Breadcrumb
        label={` ${BlockNo ? `${BlockNo},` : ""} Plot ${
          PlotNo ? `${PlotNo},` : ""
        } ${StreetId ?? ""}`}
      />
      <SC.ListSubheader>
        <SC.SubHeader
          headerData={{
            header: propertiesHeader,
            content: headerContent,
            source: "KCCA",
          }}
          tabsData={tabsData}
        />
      </SC.ListSubheader>
      <SC.Box>
        <Switch>
          {routes.map((route) => {
            const key = _.isArray(route.path)
              ? route.path[0]
              : route.path ?? "Content-Not-Found";

            return <Route key={key} {...route} />;
          })}
        </Switch>
      </SC.Box>
    </SC.Container>
  );
};

export default PropertiesTabPage;
