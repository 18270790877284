/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Box as ToolbarActions,
  Tooltip,
  IconButton as IconButtonBase,
} from "@material-ui/core";

const IconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      fontSize: theme.table.toolbarActions.fontSize,
    },
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.table.toolbarActions.iconColor,
    },
  },
}))(IconButtonBase);

export default {
  ToolbarActions,
  Tooltip,
  IconButton,
};
