/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraNonIndividualReportFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTableSchema = Omit<
  UraNonIndividualReportFragment,
  "__typename"
>;

export const NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTableSchema,
  "string" | "number" | "Date"
> = {
  ActvtyDesc: "string",
  AppliedForTin: "string",
  AuthctcdEmailId: "string",
  AuthctcdFamilyName: "string",
  AuthctcdFirstName: "string",
  AuthctcdLandlineNumber: "string",
  AuthctcdMobileNumber: "string",
  AuthctcdOtherName: "string",
  AuthctcdPrsnDesgn: "string",
  AuthctcdSurname: "string",
  BsnsaddrBuildname: "string",
  BsnsaddrDistrictName: "string",
  BsnsaddrLocalCouncil: "string",
  BsnsaddrParishName: "string",
  BsnsaddrPlotNo: "string",
  BsnsaddrStreetAddr: "string",
  BsnsaddrSubCountyName: "string",
  BsnsaddrTownName: "string",
  BsnsaddrTradeCentre: "string",
  BsnsaddrVillageName: "string",
  BsnsCertNumber: "string",
  BsnsName: "string",
  BusinessStartDt: "Date",
  EmailId: "string",
  ExistTinNo: "string",
  HoldingCompName: "string",
  HoldingCompTin: "string",
  IsHldCmpInUganda: "string",
  IsIncomeSrcBsns: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  IsSubsidaryComp: "string",
  LandlineNumber: "string",
  MainActivity: "string",
  MobileNumber: "string",
  OrgSubType: "string",
  OrgSubTypeDesc: "string",
  OrgType: "string",
  PostaddrDistictName: "string",
  PostaddrId: "string",
  RefEmailId: "string",
  RefFamilyName: "string",
  RefFirstName: "string",
  RefLandlineNumber: "string",
  RefMiddleName: "string",
  RefMobileNumber: "string",
  RefOtherName: "string",
  RefPrsnDesgn: "string",
  RefSurname: "string",
  RefTin: "string",
  Rnid: "string",
  TaxPayerName: "string",
  TaxPayerType: "string",
  TinNo: "string",
  TradingName: "string",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTableSchema
    | Accessor<NonIndividualTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nonIndividualTableColumns: TableColumn<NonIndividualTableSchema>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "left",
      Cell: ({
        value,
        row: {
          values: { TinNo },
        },
      }) => {
        return (
          <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Entity's Legal Name",
      accessor: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Main Trading/Business Name (if different)",
      accessor: "TradingName",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Address - County/Municipality",
      accessor: "BsnsaddrTownName",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Address - Parish",
      accessor: "BsnsaddrParishName",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Address - Mobile Phone Number",
      accessor: "MobileNumber",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Address - Landline Number",
      accessor: "LandlineNumber",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Address - Email Address",
      accessor: "EmailId",
      align: "center",
      format: "string",
    },
    {
      Header: "Entity Type",
      accessor: "OrgType",
      align: "center",
      format: "string",
    },
    {
      Header: "Entity SubType",
      accessor: "OrgSubType",
      align: "center",
      format: "string",
    },
    {
      Header: "Entity Subtype Description",
      accessor: "OrgSubTypeDesc",
      align: "center",
      format: "string",
    },
    {
      Header: "Select Income Source - Rental ",
      accessor: "IsIncomeSrcRental",
      align: "center",
      format: "string",
    },
    {
      Header: "Select Income Source - Business",
      accessor: "IsIncomeSrcBsns",
      align: "center",
      format: "string",
    },
    {
      Header: "Select Income Source - Property",
      accessor: "IsIncomeSrcPrpty",
      align: "center",
      format: "string",
    },
    {
      Header: "Select Income Source - Major Business Activity",
      accessor: "MainActivity",
      align: "center",
      format: "string",
    },
    {
      Header: "Select Income Source - Best Match  of Business Activity",
      accessor: "ActvtyDesc",
      align: "center",
      format: "string",
    },
    {
      Header: "Previously Issued a TIN",
      accessor: "AppliedForTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - Email",
      accessor: "AuthctcdEmailId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Persons Associated with Entity - Father Surname",
      accessor: "AuthctcdFamilyName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - First Name",
      accessor: "AuthctcdFirstName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - Land Line Number",
      accessor: "AuthctcdLandlineNumber",
      align: "right",
      hiddenColumn: true,
      format: "number",
    },
    {
      Header: "Authenticator - Mobile Phone Numberee",
      accessor: "AuthctcdMobileNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - Acquired Name",
      accessor: "AuthctcdOtherName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - Position Held",
      accessor: "AuthctcdPrsnDesgn",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Authenticator - Surname/Maiden Name",
      accessor: "AuthctcdSurname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Building Name",
      accessor: "BsnsaddrBuildname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address -District/City",
      accessor: "BsnsaddrDistrictName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Local Councile",
      accessor: "BsnsaddrLocalCouncil",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Plot Number",
      accessor: "BsnsaddrPlotNo",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Street Name",
      accessor: "BsnsaddrStreetAddr",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Sub-County/Town Council/Division",
      accessor: "BsnsaddrSubCountyName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Adddress- Trade Center",
      accessor: "BsnsaddrTradeCentre",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Address - Village",
      accessor: "BsnsaddrVillageName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Registrattion Number",
      accessor: "BsnsCertNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Business Name",
      accessor: "BsnsName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Date Entity Established",
      accessor: "BusinessStartDt",
      align: "center",
      hiddenColumn: true,
      format: "date",
    },

    {
      Header: "If Yes, Previous TIN",
      accessor: "ExistTinNo",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Holding Company Registered Company Name",
      accessor: "HoldingCompName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Holding Company TIN",
      accessor: "HoldingCompTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Principlee Holding Company in Uganda",
      accessor: "IsHldCmpInUganda",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Registering a Subsidiary",
      accessor: "IsSubsidaryComp",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "District/City where box is located",
      accessor: "PostaddrDistictName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Post Office Box Number/Private Bag",
      accessor: "PostaddrId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - email",
      accessor: "RefEmailId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Family/Father's Surname",
      accessor: "RefFamilyName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - First Name",
      accessor: "RefFirstName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Land Line Number",
      accessor: "RefLandlineNumber",
      align: "right",
      hiddenColumn: true,
      format: "number",
    },
    {
      Header: "Referee Contact - Middle Name",
      accessor: "RefMiddleName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Mobile Phone Numberee",
      accessor: "RefMobileNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Acquired Name",
      accessor: "RefOtherName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Designation/Relationship",
      accessor: "RefPrsnDesgn",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Surname/Maiden Name",
      accessor: "RefSurname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Referee Contact - Existing TIN",
      accessor: "RefTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Tax Payer Type",
      accessor: "TaxPayerType",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
  ];
