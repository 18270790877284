import {
  withStyles,
  Container as ContainerBase,
  Box as BoxBase,
  Typography as TypographyBase,
} from "@material-ui/core";

const Container = withStyles(() => ({
  root: {
    overflowX: "hidden",
    maxWidth: "inherit",
  },
}))(ContainerBase);

const Typography = TypographyBase;

const Box = BoxBase;

export default {
  Container,
  Box,
  Typography,
};
