/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Card as CardBase,
  CardHeader as CardHeaderBase,
  Fab,
  Box,
  Button as ButtonBase,
  CircularProgress,
  Typography,
} from "@material-ui/core";

const Card = withStyles(() => ({
  root: {
    maxWidth: "fit-content",
  },
}))(CardBase);

const CardHeader = withStyles((theme: any) => ({
  action: {
    marginLeft: theme.spacing(1),
    alignSelf: "center",
  },
}))(CardHeaderBase);

const StatusIndicator = withStyles(() => ({
  root: {
    cursor: "default",
  },
}))(Fab);

const ButtonWrapper = withStyles(() => ({
  root: {
    position: "relative",
  },
}))(Box);

const Button = withStyles(() => ({
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

const IndicatorProgress = withStyles(() => ({
  root: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
}))(CircularProgress);

const ButtonProgress = withStyles(() => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))(CircularProgress);

const Text = withStyles(() => ({
  root: {},
}))(Typography);

export default {
  Card,
  CardHeader,
  StatusIndicator,
  ButtonWrapper,
  Button,
  IndicatorProgress,
  ButtonProgress,
  Text,
};
