/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";

const BannerGraphic = withStyles((theme: any) => ({
  root: {
    minWidth: "fit-content",
    boxShadow: "none",
    backgroundColor: "transparent",
    "& button": {
      display: "flex",
      height: "100%",
      pointerEvents: "none",
      flexDirection: "column",
      [theme.breakpoints.down("xl")]: {
        paddingTop: theme.bannerGraphicSmall.paddingTop,
        paddingRight: theme.bannerGraphicSmall.paddingRight,
        paddingBottom: theme.bannerGraphicSmall.paddingBottom,
        paddingLeft: theme.bannerGraphicSmall.paddingLeft,
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: theme.bannerGraphic.paddingTop,
        paddingRight: theme.bannerGraphic.paddingRight,
        paddingBottom: theme.bannerGraphic.paddingBottom,
        paddingLeft: theme.bannerGraphic.paddingLeft,
      },
    },
  },
}))(Card);

const Logo = withStyles({
  root: {
    width: "32%",
    height: "4rem",
  },
})(CardMedia);

const TextLogoContent = withStyles({
  root: {
    padding: "0",
  },
})(CardContent);

const Text = withStyles((theme: any) => ({
  root: {
    marginBottom: "0",
    fontWeight: theme.bannerGraphicText.fontWeight,
    color: theme.bannerGraphicText.color,
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.bannerGraphicTextSmall.fontSize,
      lineHeight: theme.bannerGraphicTextSmall.lineHeight,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.bannerGraphicText.fontSize,
      lineHeight: theme.bannerGraphicText.lineHeight,
    },
  },
}))(Typography);

export default {
  BannerGraphic,
  Logo,
  TextLogoContent,
  Text,
};
