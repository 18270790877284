/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Link } from "react-router-dom";
import { TableColumn } from "components/table/table.component";
import FormattedValue from "components/formatted-value/formatted-value";

type IndividualCompliance = {
  category: string;
  route: string;
  count: number;
  totalRentedProperties: number;
  grossRentedIncome: number;
  declaredRentedIncome: number;
};

export const columns: TableColumn<IndividualCompliance>[] = [
  {
    Header: "Compliance Category",
    accessor: "category",
    align: "left",
    Cell: ({
      value,
      row: {
        values: { route },
      },
    }: {
      value: string;
      row: { values: { route: string } };
    }): React.ReactNode => {
      return route ? (
        <Link to={`/reports/analytics/${route}/individuals`}>{value}</Link>
      ) : (
        <>{value}</>
      );
    },
    // @ts-ignore
    Footer: "Total",
  },
  {
    Header: "Route",
    accessor: "route",
    align: "left",
    sort: false,
    hiddenColumn: true,
  },

  {
    Header: "No. of Owners",
    accessor: "count",
    align: "right",
    format: "number",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }): React.ReactNode => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { count: unknown } }) =>
              // @ts-ignore
              row.values.count + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="number" value={total as number} />;
    },
  },

  {
    Header: "Total No. of Rented Properties",
    accessor: "totalRentedProperties",
    align: "right",
    format: "number",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }): React.ReactNode => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { totalRentedProperties: unknown } }) =>
              // @ts-ignore
              row.values.totalRentedProperties + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="number" value={total as number} />;
    },
  },
  {
    Header: "Total Sum of Expected Gross Rent Income (UGX)",
    accessor: "declaredRentedIncome",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }): React.ReactNode => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { declaredRentedIncome: unknown } }) =>
              // @ts-ignore
              row.values.declaredRentedIncome + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
  {
    Header: "Total Sum of Declared Gross Rent Income (UGX)",
    accessor: "grossRentedIncome",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }): React.ReactNode => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { grossRentedIncome: unknown } }) =>
              // @ts-ignore
              row.values.grossRentedIncome + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
];

export default columns;
