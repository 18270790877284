/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  makeStyles,
  Grid as GridMui,
  GridProps,
  InputLabel,
  withStyles,
  Box,
  Checkbox,
} from "@material-ui/core/";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
  scrollable?: boolean;
  paddingright?: string;
}

const useGridStyles = makeStyles((theme: any) => ({
  root: {
    alignSelf: "center",
    textAlign: "center",
    display: "flex",
    position: "relative",
    marginTop: (props: CustomGridProps) =>
      props.MarginTop || props.MarginTop === 0
        ? props.MarginTop
        : theme.spacing(2),
    overflow: (props: CustomGridProps) => (props.scrollable ? "auto" : "unset"),
    paddingRight: (props: CustomGridProps) =>
      props.paddingright ? props.paddingright : "none",
  },
}));

const Grid = (props: CustomGridProps): JSX.Element => {
  const { MarginTop, scrollable, ...muiProps } = props;
  const classes = useGridStyles(props);

  return <GridMui className={classes.root} {...muiProps} />;
};

const InputLabelPermissions = withStyles((theme: any) => ({
  root: {
    textAlign: theme.InputLabel.textAlign,
    // position: theme.InputLabel.position,
    marginLeft: theme.InputLabel.marginLeft,
  },
}))(InputLabel);

const InputLabelTab = withStyles((theme: any) => ({
  root: {
    textAlign: theme.InputLabel.textAlign,
    position: theme.InputLabel.position,
  },
}))(InputLabel);

const PermissionsHeading = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Box);

export default {
  Grid,
  InputLabelPermissions,
  InputLabelTab,
  PermissionsHeading,
  Checkbox,
};
