/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Grid as GridBase,
  Box as BoxBase,
  Typography as TypographyBase,
  Container as ContainerBase,
  Divider as DividerBase,
} from "@material-ui/core";

import RoomIcon from "@material-ui/icons/Room";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";
import MapContainerBase from "components/map-container/map-container.component";

const Grid = GridBase;
const Box = withStyles({
  root: {
    textAlign: "center",
  },
})(BoxBase);

const Typography = TypographyBase;

const SwitchViews = SwitchViewsBase;

const LabelKeyValue = withStyles({
  root: {
    alignItems: "center",
  },
})(LabelKeyValueBase);

const Container = withStyles({
  root: {
    padding: 0,
    margin: 0,
  },
})(ContainerBase);

const Divider = withStyles({
  root: {
    color: "red",
  },
})(DividerBase);

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
}))(Typography);

export default {
  Grid,
  Box,
  Typography,
  Title,
  SwitchViews,
  LabelKeyValue,
  Container,
  Divider,
  MapContainer,
  MapTableContainer,
  RoomIcon,
};
