/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Avatar,
  Box as BoxBase,
  Typography as TypographyBase,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const AuthorBox = withStyles(() => ({
  root: {
    display: "flex",
    margin: 0,
    marginTop: "1.14rem",

    "& > *": {
      marginRight: "1.14rem",
    },
  },
}))(BoxBase);

const UserAvatar = withStyles((theme: any) => ({
  root: {
    width: theme.notes.authorProfilePicture.width,
    height: theme.notes.authorProfilePicture.height,
  },
}))(Avatar);

const DefaultAvatarIcon = withStyles((theme: any) => ({
  root: {
    width: theme.notes.authorProfilePicture.width,
    height: theme.notes.authorProfilePicture.height,
    color: theme.notes.authorProfilePicture.defaultColor,
  },
}))(AccountCircleIcon);

const AuthorName = withStyles((theme: any) => ({
  root: {
    fontSize: theme.notes.authorName.fontSize,
  },
}))(TypographyBase);

export default {
  AuthorName,
  AuthorBox,
  UserAvatar,
  DefaultAvatarIcon,
};
