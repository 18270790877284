// Dependencies
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core";

const AlertBase = withStyles(() => ({
  root: {
    width: "100%",
    // position: "absolute",
    bottom: 0,
  },
}))(Alert);

export default {
  Alert,
  AlertBase,
};
