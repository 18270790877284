// Dependencies
import React, { useMemo } from "react";
import Table from "components/table/table.component";
import { PropertiesValuationFragment } from "graphql/hasura/types-and-hooks";
import {
  COMMERCIAL,
  RESIDENTIAL_SUB_SFH,
} from "pages/properties-tab/details-tab/details-constant";
import {
  CommercialColumns,
  ResidentialSubSFHColumns,
} from "./gazette-rates.schema";

interface PropertyProps {
  data: PropertiesValuationFragment[];
}

const PrimaryTable: React.FC<PropertyProps> = ({ data }) => {
  const residentialSubSFHData = useMemo(() => {
    return data.map((context) => {
      return {
        Village: context.Village,
        Derivednoofbedrooms: context.DerivedNoOfBedrooms,
        Monthlygazettevalue: context.MonthlyGazetteValue,
        Annualgazettevalue: context.AnnualGazetteValue,
      };
    });
  }, [data]);

  const commercialData = useMemo(() => {
    return data.map((context) => {
      return {
        Village: context.Village,
        Squaremeters: context.SquareMeters,
        MonthlyChargeSquare:
          Number(context.MonthlyGazetteValue || 0) > 0
            ? Number(context.MonthlyGazetteValue || 0) /
              Number(context.SquareMeters || 1)
            : 0,
        MonthlyCharge: context.MonthlyGazetteValue,
        AnnualCharge: context.AnnualGazetteValue,
      };
    });
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { Columns, valuationData }: any = React.useMemo(() => {
    const { PropertyType, RnSubpropertyType } = data[0];
    // commercial Sub Ware houses and Bars
    if (PropertyType === COMMERCIAL) {
      return {
        Columns: CommercialColumns,
        valuationData: commercialData,
      };
    }

    // Residential Simple Family Homes
    if (RnSubpropertyType === RESIDENTIAL_SUB_SFH) {
      return {
        Columns: ResidentialSubSFHColumns,
        valuationData: residentialSubSFHData,
      };
    }
    return { Columns: [], valuationData: [] };
  }, [commercialData, data, residentialSubSFHData]);

  if (valuationData.length === 0) {
    return null;
  }

  return (
    <Table
      // stickyHeader
      isPaginationHidden
      onAction={() => {}}
      title="URA Gazette Rates"
      initialRowsPerPage={10000}
      columns={Columns}
      data={valuationData}
    />
  );
};

export default PrimaryTable;
