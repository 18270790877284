import React, { useMemo } from "react";
import Table from "components/table/table.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Kcca_RawProperties1Args,
  useKccaCustomerAlternateOwnerQuery,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Schema
import { kccaCustomerAlternateOwnerTableColumns } from "./alternate-table.schema";

interface PropertyProps {
  propertyIdentifier: string;
}

const AlternateTable: React.FC<PropertyProps> = ({ propertyIdentifier }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Kcca_RawProperties1Args = {
    where: { Id: { _eq: propertyIdentifier } },
  };
  const { data: dataAlternateOwner, error: errorAlternateOwner } =
    useKccaCustomerAlternateOwnerQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !propertyIdentifier,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    });

  useAppErrorHandler(errorAlternateOwner);

  const kccaAlternateOwnerData = React.useMemo(() => {
    const kcca = dataAlternateOwner?.rtcs_db_Fnkcca_RawProperties1 ?? [];
    return kcca;
  }, [dataAlternateOwner]);

  const columnsAlternate = useMemo(
    () => kccaCustomerAlternateOwnerTableColumns,
    []
  );

  return (
    <Table
      // stickyHeader
      isPaginationHidden
      onAction={() => {}}
      title="Alternate"
      initialRowsPerPage={10000}
      columns={columnsAlternate}
      data={kccaAlternateOwnerData}
    />
  );
};

export default AlternateTable;
