/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UraRentalLandLordIncomeFragment } from "graphql/hasura/types-and-hooks";
import { TableColumn } from "components/table/table.component";

export type ReportTableSchema = Omit<
  UraRentalLandLordIncomeFragment,
  "__typename" | "TinNo" | "RtnRentalIncomeId"
>;

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "Premises Location",
    accessor: "PremiseLocation",
  },
  {
    Header: "Rental Income",
    accessor: "RentalIncome",
    format: "currency",
    align: "right",
  },
  {
    Header: "Return Date",
    accessor: "RtnDt",
    format: "date",
  },
  {
    Header: "Return From Date",
    accessor: "RtnFromDt",
    format: "date",
  },
  {
    Header: "Return No.",
    accessor: "RtnNo",
    sort: true,
  },
  {
    Header: "Return To Date",
    accessor: "RtnToDt",
    format: "date",
  },
  {
    Header: "Tenancy Period From",
    accessor: "TenancyPeriodFrom",
    format: "date",
  },
  {
    Header: "Tenancy Period To",
    accessor: "TenancyPeriodTo",
    format: "date",
  },
  {
    Header: "TaxPayer Name",
    accessor: "TaxPayerName",
  },
  {
    Header: "Tenant Name",
    accessor: "TenantName",
  },
  {
    Header: "Tenant TIN",
    accessor: "TenantTin",
  },
];
