/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useMemo } from "react";
import { CLIENT_NAME } from "graphql/client";
// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

import useExportData from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  useRpt_PropertyCompositeQuery,
  Query_RootRtcs_Db_Rpt_PropertiesCompositeArgs,
  useGetPaginatedRpt_PropertyCompositeQuery,
  Order_By,
  Rtcs_Db_Rpt_PropertiesComposite_Select_Column,
} from "graphql/hasura/types-and-hooks";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import { ColumnInstance } from "react-table";
import {
  propertiesTableColumns,
  getAccessorType,
  PropertiesTableSchema,
} from "./properties.schema";

// Styles
import SC from "./properties.styles";

const TABLE_NAME = "rtcs_db_FnRPT_PropertiesComposite";

export const PropertyPage = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Rpt_PropertiesCompositeArgs | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedRpt_PropertyCompositeQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const propertiesData: PropertiesTableSchema[] = React.useMemo(() => {
    return data?.rtcs_db_FnRPT_PropertiesComposite ?? [];
  }, [data]);

  const columns = useMemo(() => propertiesTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Rpt_PropertiesComposite_Select_Column.Rnid,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;

      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_PropertiesComposite_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [handlePaginatedFetchData, loading, totalRowsCount]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: PropertiesFilterFn } = useRpt_PropertyCompositeQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Rpt_PropertiesCompositeArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Rpt_PropertiesComposite_Select_Column,
              ],
              limit: 10,
            };

          const individualFilterData = await PropertiesFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });

          suggestions =
            individualFilterData.data?.rtcs_db_FnRPT_PropertiesComposite.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }
        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [PropertiesFilterFn]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Building Name",
        columnName: Rtcs_Db_Rpt_PropertiesComposite_Select_Column.MapTitle,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Property Type",
        columnName:
          Rtcs_Db_Rpt_PropertiesComposite_Select_Column.PropertyTypeId,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Property Subtype",
        columnName:
          Rtcs_Db_Rpt_PropertiesComposite_Select_Column.SubPropertyTypeId,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
        offset: 0,
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const dataExport = useCallback(
    (cols: ColumnInstance[]) =>
      exportDataFn(cols.filter((col) => col.Header !== "Property Owner")),
    [exportDataFn]
  );

  return (
    <SC.Box>
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
        ]}
        title="Properties"
        columns={columns}
        data={propertiesData}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "First name, Surname or Legal Entity Name",
                columnNames: [
                  Rtcs_Db_Rpt_PropertiesComposite_Select_Column.Firstname,
                  Rtcs_Db_Rpt_PropertiesComposite_Select_Column.Surname,
                  Rtcs_Db_Rpt_PropertiesComposite_Select_Column.EntityLegalName,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="979d965b-3644-4c36-848a-36471-7835-7632"
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        stickyHeader
        exportData={dataExport}
      />

      <ProgressIndicator open={loading || exportLoading} />
    </SC.Box>
  );
};

export default PropertyPage;
