import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import React from "react";

import SC from "./property-details.styles";

type Details = {
  index: number;
  label: string;
  value: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
};
type PropertyDetailsProps = {
  title: string;
  details: Details[];
};

const drawList = (details: Details[]) =>
  details.map(({ index, label, value, format }) => (
    <SC.PropertyDetailsItem
      label={label}
      value={value}
      key={index}
      format={format ?? "string"}
    />
  ));

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  title,
  details,
}) => {
  return (
    <SC.Paper elevation={0}>
      <SC.Typography variant="h6">{title}</SC.Typography>
      {drawList(details)}
    </SC.Paper>
  );
};

export default PropertyDetails;
