/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  Grid as GridBase,
  Paper as PaperBase,
  Box as BoxBase,
  BoxProps,
  Checkbox,
  Container as ContainerBase,
  Button as ButtonBase,
  CardMedia as CarmediaBase,
  Typography as TypographyBase,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Link as LinkBase, LinkProps } from "react-router-dom";
import { Alert as AlertBase } from "components/alert/alert.component";
// Asset
import BGImage from "assets/images/URA_Background.png";

const Container = withStyles({
  root: {
    height: "100%",
    position: "absolute",
    maxWidth: "none",
    backgroundImage: `url(${BGImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
})(ContainerBase);

const Grid = withStyles({
  root: {
    justifyContent: "center",
    textAlign: "center",
  },
})(GridBase);

const GridParent = withStyles((theme: any) => ({
  root: {
    justifyContent: "center",
    textAlign: "center",
    height: "100%",
    left: "0",
    position: "absolute",
    paddingTop: theme.spacing(6),
  },
}))(GridBase);

const Paper = withStyles((theme: any) => ({
  root: {
    height: "100%",
    borderRadius: theme.landingPage.borderRadius,
  },
}))(PaperBase);

const ImageBox = withStyles((theme: any) => ({
  root: {
    paddingTop: theme.spacing(6),
  },
}))(BoxBase);

const TexFieldBox = withStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))(BoxBase);

const TermsBox = withStyles((theme: any) => ({
  root: {
    position: "absolute",
    bottom: theme.landingPage.termsBox.bottom,
    left: theme.landingPage.termsBox.left,
    transform: "translate(-50%, -50%)",
  },
}))(BoxBase);

const HelpContainerBox = withStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
})(BoxBase);

const HelpBoxRight = withStyles({
  root: {
    textAlign: "right",
  },
})(BoxBase);

const HelpBoxLeft = withStyles((theme: any) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
}))(BoxBase);

const RememberBox = withStyles({
  root: {
    textAlign: "left",
  },
})(BoxBase);

interface CustomBoxProps extends BoxProps {
  MarginTop?: number;
}

const useBoxStyles = makeStyles((theme: any) => ({
  root: {
    alignSelf: "center",
    textAlign: "center",
    marginTop: (props: CustomBoxProps) =>
      props.MarginTop ? props.MarginTop : theme.spacing(2),
  },
}));

const Box = (props: CustomBoxProps): JSX.Element => {
  const { MarginTop, ...muiProps } = props;
  const classes = useBoxStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};
const Button = withStyles((theme: any) => ({
  root: {
    height: theme.landingPage.button.height,
    color: theme.table.toolbarActions.iconColor,
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
    width: "100%",
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

const ButtonContainer = withStyles((theme: any) => ({
  root: {
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: "100%",
  },
}))(MuiDialogActions);

const CardMedia = withStyles((theme: any) => ({
  root: {
    width: theme.spacing(40),
    height: theme.spacing(25),
    margin: "auto",
  },
}))(CarmediaBase);

const Typography = withStyles((theme: any) => ({
  root: {
    color: theme.landingPage.typography.color,
    marginTop: theme.landingPage.typography.marginTop,
  },
}))(TypographyBase);

const LinkText = withStyles((theme: any) => ({
  root: {
    color: theme.landingPage.typography.color,
    textDecoration: "none",
  },
}))(TypographyBase);

type LinkBreadcrumbProps = LinkProps;

const CustomLinkStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
    pointerEvents: "auto",
    fontSize: theme.landingPage.typography.fontSize,
    textTransform: "capitalize",
    textDecoration: "none",
  },
}));

const Link: React.FC<LinkBreadcrumbProps> = (props) => {
  const { to, ...muiProps } = props;
  const classes = CustomLinkStyles();

  return <LinkBase to={to} className={classes.root} {...muiProps} />;
};

const HelpLink = withStyles((theme: any) => ({
  root: {
    color: theme.landingPage.typography.color,
    textDecoration: "none",
    fontSize: theme.landingPage.typography.fontSize,
  },
}))(TypographyBase);

const HelpText = withStyles((theme: any) => ({
  root: {
    fontSize: theme.landingPage.typography.fontSize,
    color: theme.palette.primary.main,
  },
}))(TypographyBase);

const Alert = withStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))(AlertBase);
export default {
  Box,
  ImageBox,
  TexFieldBox,
  TermsBox,
  HelpContainerBox,
  HelpBoxRight,
  HelpBoxLeft,
  RememberBox,
  Checkbox,
  Grid,
  GridParent,
  Paper,
  Container,
  Button,
  ButtonContainer,
  CardMedia,
  Typography,
  Link,
  LinkText,
  HelpLink,
  HelpText,
  Alert,
};
