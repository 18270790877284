import { withStyles, Button as ButtonBase } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = withStyles((theme: any) => ({
  root: {
    height: "1.8rem",
    color: theme.table.toolbarActions.iconColor,
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

export default {
  Button,
};
