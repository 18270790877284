import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  OwnersAndOfficersFragment,
  NonIndividualsAssociatedIndividualsFragment,
  IndividualsAssociatedBusinessFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export const associatedBusinessescolumns: TableColumn<NonIndividualsAssociatedIndividualsFragment>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "left",
    },
    {
      Header: "Associate TIN",
      accessor: "AssociateTin",
      align: "left",
      Cell: ({
        value,
        row: {
          values: { AssociateTin },
        },
      }: {
        value: string;
        row: { values: { AssociateTin: string } };
      }): React.ReactNode => {
        return isValidTin(AssociateTin) ? (
          <Link
            to={`/non-individuals/${AssociateTin}/identification`}
            color="inherit"
          >
            {value}
          </Link>
        ) : (
          <>{AssociateTin}</>
        );
      },
    },
    {
      Header: "Associated Business",
      accessor: "AssociatedNonIndividual",
      align: "left",
    },
    {
      Header: "Main Activity  ",
      accessor: "MainActivity",
      align: "left",
    },

    {
      Header: "Activity Description",
      accessor: "ActvtyDesc",
      align: "left",
    },
  ];

export const associatedIndividualscolumns: TableColumn<IndividualsAssociatedBusinessFragment>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "left",
      sort: false,
      Cell: ({
        value,
        row: {
          values: { TinNo },
        },
      }: {
        value: string;
        row: { values: { TinNo: string } };
      }): React.ReactNode => {
        return isValidTin(TinNo) ? (
          <Link to={`/individuals/${TinNo}/identification`}>{value}</Link>
        ) : (
          <>{TinNo}</>
        );
      },
    },
    {
      Header: "First Name",
      accessor: "FirstName",
      align: "left",
      sort: false,
    },
    {
      Header: "Surname",
      accessor: "Surname",
      align: "left",
      sort: false,
    },
    {
      Header: "Other Name",
      accessor: "OtherName",
      align: "left",
      sort: false,
    },
    {
      Header: "Family Name",
      accessor: "FamilyName",
      align: "left",
      sort: false,
    },
    {
      Header: "Business TIN",
      accessor: "AssociateTin",
      align: "left",
      sort: false,
    },
    {
      Header: "Entity Relationship",
      accessor: "EntityDesignation",
      align: "left",
      sort: false,
    },
    {
      Header: "Main Activity",
      accessor: "MainActivity",
      align: "left",
      sort: false,
    },
    {
      Header: "Activity Description",
      accessor: "ActvtyDesc",
      align: "left",
      sort: false,
    },
  ];

export const columns: TableColumn<OwnersAndOfficersFragment>[] = [
  {
    Header: "Individual TIN No.",
    accessor: "Individualtin",
    align: "left",
    sort: false,
    Cell: ({
      value,
      row: {
        values: { Individualtin },
      },
    }: {
      value: string;
      row: { values: { Individualtin: string } };
    }): React.ReactNode => {
      return isValidTin(Individualtin) ? (
        <Link
          to={`/individuals/${Individualtin}/identification`}
          color="inherit"
        >
          {value}
        </Link>
      ) : (
        <>{Individualtin}</>
      );
    },
  },
  {
    Header: "Name",
    accessor: "Name",
    align: "left",
    sort: false,
  },
  {
    Header: "Business TIN No.",
    accessor: "Tinno",
    align: "left",
    sort: false,
  },
  {
    Header: "Business Name",
    accessor: "Taxpayername",
    align: "left",
    sort: false,
  },
  {
    Header: "Designation",
    accessor: "Designation",
    align: "center",
    sort: false,
  },
  {
    Header: "Percent Owned",
    accessor: "Pershare",
    align: "right",
    sort: false,
  },
  {
    Header: "Address",
    accessor: "Address",
    align: "left",
    sort: false,
  },
  {
    Header: "Return No.",
    accessor: "Rtnno",
    align: "left",
    sort: false,
  },
  {
    Header: "Tax Year",
    accessor: "Rtnperiodyear",
    align: "left",
    sort: false,
  },
  {
    Header: "Officers Index No.",
    accessor: "Rtnholdingofficersdtlid",
    align: "left",
    sort: false,
    hiddenColumn: true,
  },
  {
    Header: "Owners Index No.",
    accessor: "Rtnholdingownersdtlid",
    align: "left",
    sort: false,
    hiddenColumn: true,
  },
];

export default {
  columns,
};
