// Dependencies
import React from "react";

// Asses
import SC from "./progress-indicator.styles";

export interface ProgressIndicatorProps {
  open: boolean;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  open,
}) => {
  return (
    <SC.Dialog open={open}>
      <SC.ProgressIndicator />
    </SC.Dialog>
  );
};

export default ProgressIndicator;
