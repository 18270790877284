import FormattedValue, {
  FORMAT_VALUE_OPTIONS,
} from "components/formatted-value/formatted-value";
import React from "react";

import SC from "./property-details-item.styles";

type PropertyDetailsItemProps = {
  label: string;
  value: string;
  format: keyof typeof FORMAT_VALUE_OPTIONS;
};

const PropertyDetailsItem: React.FC<PropertyDetailsItemProps> = ({
  label,
  value,
  format,
}) => {
  return (
    <SC.Grid container>
      <SC.Grid item xs={6}>
        <SC.Label>{label}</SC.Label>
      </SC.Grid>
      <SC.Grid item xs={6}>
        <SC.Value>
          <FormattedValue value={value} format={format} />
        </SC.Value>
      </SC.Grid>
    </SC.Grid>
  );
};

export default PropertyDetailsItem;
