/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from "lodash";
import { RptRentalTenantPaymentsFragment } from "graphql/hasura/types-and-hooks";
import { Accessor } from "react-table";
import { TableColumn } from "components/table/table.component";

export type ReportTableSchema = Omit<
  RptRentalTenantPaymentsFragment,
  "__typename"
>;

export const TENANTS_RENTAL_PAYMENT_TABLE_SCHEMA_DATA_TYPE: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  LandlordName: "string",
  LandlordTin: "string",
  TenantName: "string",
  TenantTin: "string",
  PremiseLocation: "string",
  RentPaid: "number",
  RtnNo: "string",
  TenancyPeriodFrom: "string",
  TenancyPeriodTo: "string",
  RtnRentPaidId: "string",
  File: "string",
  IsAmendment: "string",
  LastUpdated: "string",
  Line: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TENANTS_RENTAL_PAYMENT_TABLE_SCHEMA_DATA_TYPE[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "LandLord Name",
    accessor: "LandlordName",
    sort: true,
  },
  {
    Header: "Landlord TIN",
    accessor: "LandlordTin",
  },
  {
    Header: "Tenant Name",
    accessor: "TenantName",
    sort: true,
  },
  {
    Header: "Tenant TIN",
    accessor: "TenantTin",
  },
  {
    Header: "Premises Location",
    accessor: "PremiseLocation",
    format: "string",
  },
  {
    Header: "Rent Paid",
    accessor: "RentPaid",
    format: "currency",
    align: "right",
  },
  {
    Header: "Return No.",
    accessor: "RtnNo",
  },
  {
    Header: "Return Rent Paid Id",
    accessor: "RtnRentPaidId",
  },
  {
    Header: "Tenancy Period From",
    accessor: "TenancyPeriodFrom",
    format: "date",
  },
  {
    Header: "Tenancy Period To",
    accessor: "TenancyPeriodTo",
    format: "date",
  },
  {
    Header: "Is Amendment",
    accessor: "IsAmendment",
    format: "string",
  },
];
