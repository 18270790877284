import { TableColumn } from "components/table/table.component";

export type UtilitiesTableSchema = {
  month: Date;
  pay: string;
  trans: string;
  cons: string;
};

export const utilitiesTableColumns: TableColumn<UtilitiesTableSchema>[] = [
  {
    Header: "Monthly Activity",
    accessor: "month",
    align: "left",
  },
  {
    Header: "Payment",
    accessor: "pay",
    align: "right",
    format: "currency",
  },
  {
    Header: "Transaction",
    accessor: "trans",
    align: "right",
    format: "currency",
  },
  {
    Header: "Consumption",
    accessor: "cons",
    align: "center",
  },
];
