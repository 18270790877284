/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  IconButton as IconButtonBase,
  Grid as GridBase,
  Typography,
  ListSubheader,
} from "@material-ui/core";

const LabelButtonText = withStyles((theme: any) => ({
  root: {
    fontSize: theme.expandAllButton.fontSize,
    fontWeight: theme.expandAllButton.fontWeight,
    textAlign: "center",
  },
}))(Typography);

const Grid = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.accordionBody.backgroundColor,
    zIndex: 1,
    maxWidth: "inherit",
  },
}))(GridBase);

const IconButton = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.accordionBody.backgroundColor,
    },
  },
}))(IconButtonBase);

export default {
  IconButton,
  Grid,
  LabelButtonText,
  ListSubheader,
};
