/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";
import { KccaCustomerRegistrationFragment } from "graphql/hasura/types-and-hooks";

export type KccaCustomerRegistrationSchema = Omit<
  KccaCustomerRegistrationFragment,
  "__typename"
>;
// !TODO ts-ignore to be removed when new changes of the table are relected.
// @ts-ignore
export const KccaCustomerRegistrationSchemaMap: Record<
  keyof KccaCustomerRegistrationSchema,
  "string" | "number"
> = {
  Id: "number",
  Coin: "string",
  CustomerType: "string",
  Firstname: "string",
  MiddleName: "string",
  Surname: "string",
  MotherMaidenName: "string",
  Tin: "string",
  IdentificationNationalId: "string",
  Birthdate: "string",
  EntityLegalName: "string",
  BuildingName: "string",
  BusinessName: "string",
  CustomerApplicantType: "string",
  CustomerBusinessType: "string",
  DateOfIncorporation: "string",
  Email: "string",
  Mobile: "string",
  SecondMobile: "string",
};

export const getAccessorType = (
  accessor:
    | keyof KccaCustomerRegistrationSchema
    | Accessor<KccaCustomerRegistrationSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return KccaCustomerRegistrationSchemaMap[accessor];
};

export const customerTableColumns: TableColumn<KccaCustomerRegistrationSchema>[] =
  [
    {
      Header: "KCCA ID",
      accessor: "Id",
      align: "center",
      sort: false,
    },
    {
      Header: "KCCA COIN",
      accessor: "Coin",
      align: "center",
      sort: false,
    },
    {
      Header: "Customer Type",
      accessor: "CustomerType",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "First Name",
      accessor: "Firstname",
      align: "left",
      sort: false,
      format: "string",
    },
    {
      Header: "Middle Name",
      accessor: "MiddleName",
      align: "left",
      sort: false,
      format: "string",
    },
    {
      Header: "Surname",
      accessor: "Surname",
      align: "left",
      sort: false,
      format: "string",
    },
    {
      Header: "Mother's Maiden Name",
      accessor: "MotherMaidenName",
      align: "left",
      sort: false,
      format: "string",
    },
    {
      Header: "KCCA TIN No.",
      accessor: "Tin",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "NIN",
      accessor: "IdentificationNationalId",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "Date Of Birth",
      accessor: "Birthdate",
      align: "center",
      sort: false,
      format: "date",
    },
    {
      Header: "Legal Name",
      accessor: "EntityLegalName",
      align: "right",
      sort: false,
      format: "string",
    },
    {
      Header: "Building Name",
      accessor: "BuildingName",
      align: "left",
      sort: false,
      format: "string",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
      align: "right",
      sort: false,
      format: "string",
    },
    {
      Header: "Applicant Type",
      accessor: "CustomerApplicantType",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "Business Type",
      accessor: "CustomerBusinessType",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "Incorporation Date",
      accessor: "DateOfIncorporation",
      align: "center",
      sort: false,
      format: "date",
    },
    {
      Header: "Email",
      accessor: "Email",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "Mobile",
      accessor: "Mobile",
      align: "center",
      sort: false,
      format: "string",
    },
    {
      Header: "Mobile2",
      accessor: "SecondMobile",
      align: "center",
      sort: false,
      format: "string",
    },
  ];
