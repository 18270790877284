import React, { useEffect } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  usePropertiesValuationGetLazyQuery,
  Query_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs,
} from "graphql/hasura/types-and-hooks";

// Utilis
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import { useAppErrorHandler } from "errors/app.errors";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import GazetteTable from "./gazette-rates-table/gazette-rates.component";
import KccaLocalCouncil from "./kcca-local-councel/kcca-local-councel.component";

// Styles
import SC from "./valuation-tab.styles";

interface ValuationProps {
  Rnid: string;
}

const OwnerTab: React.FC<ValuationProps> = ({ Rnid }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs = {
    where: { Rnid: { _eq: Rnid } },
    limit: 1,
  };

  const [fetchValuationFn, { data, error, loading }] =
    usePropertiesValuationGetLazyQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    });

  useEffect(() => {
    fetchValuationFn();
  }, [fetchValuationFn]);

  useAppErrorHandler(error);

  if (loading) {
    return <ProgressIndicator open />;
  }

  if (data && data?.rtcs_db_FnRPT_PropertyValuation.length > 0) {
    return (
      <SC.BoxBase>
        <SC.Box>
          <GazetteTable data={data?.rtcs_db_FnRPT_PropertyValuation} />
        </SC.Box>

        <SC.Box>
          <KccaLocalCouncil data={data?.rtcs_db_FnRPT_PropertyValuation} />
        </SC.Box>
      </SC.BoxBase>
    );
  }
  return <>No Data Available</>;
};

export default OwnerTab;
