/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Grid as GridBase,
  Box,
  Typography,
} from "@material-ui/core";

const Container = withStyles({
  root: {
    flexGrow: 1,
    paddingLeft: "1.14rem",
  },
})(GridBase);

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
}))(Typography);

export default {
  Container,
  Title,
  Box,
};
