/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import { UgandaRegistrationSbFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";

export type UgandaRegistrationTableSchema = Omit<
  UgandaRegistrationSbFragment,
  "__typename"
>;

export const UGANDA_REGISTRATION_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof UgandaRegistrationTableSchema,
  "string" | "number" | "Date"
> = {
  BusinessName: "string",
  CompanyName: "string",
  RegistrationNumber: "number",
  RegistrationDate: "Date",
  Subtype: "string",
  CompanyType: "string",
  PartnersFirstname: "string",
  PartnersSurname: "string",
  DirectorFirstname: "string",
  DirectorSurname: "string",
  ShareholderFirstname: "string",
  ShareholdersSurname: "string",
  District: "string",
  Village: "string",
  Town: "string",
  Trading: "string",
  Street: "string",
  Parish: "string",
};

export const getAccessorType = (
  accessor:
    | keyof UgandaRegistrationTableSchema
    | Accessor<UgandaRegistrationTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UGANDA_REGISTRATION_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const UgandaRegistrationColumns: TableColumn<UgandaRegistrationTableSchema>[] =
  [
    {
      Header: "Business Name",
      accessor: "BusinessName",
      align: "left",
      format: "string",
    },
    {
      Header: "Company Name",
      accessor: "CompanyName",
      align: "left",
      format: "string",
    },
    {
      Header: "Registration Number",
      accessor: "RegistrationNumber",
      align: "center",
      sort: true,
    },
    {
      Header: "Registration Date",
      accessor: "RegistrationDate",
      align: "center",
      format: "date",
    },
    {
      Header: "Company Type",
      accessor: "CompanyType",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Type",
      accessor: "Subtype",
      align: "center",
      format: "string",
    },
    {
      Header: "Partners First Name",
      accessor: "PartnersFirstname",
      align: "left",
      format: "string",
    },
    {
      Header: "Partners Surname",
      accessor: "PartnersSurname",
      align: "left",
      format: "string",
    },
    {
      Header: "Director First Name",
      accessor: "DirectorFirstname",
      align: "left",
      format: "string",
    },
    {
      Header: "Director Surname",
      accessor: "DirectorSurname",
      align: "left",
      format: "string",
    },
    {
      Header: "Shareholder First Name",
      accessor: "ShareholderFirstname",
      align: "left",
      format: "string",
    },
    {
      Header: "Shareholders Surname",
      accessor: "ShareholdersSurname",
      align: "left",
      format: "string",
    },
    {
      Header: "District",
      accessor: "District",
      align: "center",
      format: "string",
    },
    {
      Header: "Village",
      accessor: "Village",
      align: "center",
      format: "string",
    },
    { Header: "Town", accessor: "Town", align: "center", format: "string" },
    {
      Header: "Trading",
      accessor: "Trading",
      align: "center",
      format: "string",
    },
    { Header: "Street", accessor: "Street", align: "center", format: "string" },
    { Header: "Parish", accessor: "Parish", align: "center", format: "string" },
  ];
