/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  FormControl as FormControlBase,
  Select as SelectBase,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const FormControl = withStyles(() => ({
  root: {
    width: "100%",
  },
}))(FormControlBase);

const Select = withStyles((theme: any) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderWidth: 0,
    textAlign: "left",
    fontSize: theme.selectField.fontSize,
    color: theme.palette.primary.main,
    elevation: 0,
  },
}))(SelectBase);

const HelperText = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.accordionBody.backgroundColor,
    paddingLeft: theme.selectField.helperTextPaddingLeft,
  },
}))(FormHelperText);

export default { FormControl, Select, MenuItem, HelperText };
