import React from "react";
import SC from "./image-placeholder.styles";

const ImagePlaceholder: React.FC = () => {
  return (
    <SC.Paper elevation={0}>
      <SC.Icon />
    </SC.Paper>
  );
};

export default ImagePlaceholder;
