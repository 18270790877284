// Dependencies
import React from "react";
import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs,
  useNonIndividualOwnersAndOfficersQuery,
  Query_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs,
  useIndividualsAssociatedBusinessQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import useExportData from "exports/useExportData";
import { ColumnInstance } from "react-table";
import {
  ownersAndOfficersColumns,
  associatedBusinessescolumns,
} from "./businesses.schema";

// Assets
import SC from "./businesses-tab.styles";

export interface OwnersOfficersProps {
  tin: string;
}

const TABLE_NAME = "ura_CombinedOfficersAndOwners";

const IndividualBusinesses: React.FC<OwnersOfficersProps> = ({ tin }) => {
  const ownersOfficersqueryVariables: Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs =
    {
      where: { Individualtin: { _eq: tin } },
    };

  const ascBusinessesQueryVariables: Query_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs =
    {
      where: { TinNo: { _eq: tin } },
    };

  const {
    loading: ownerOfficersLoading,
    data: ownerOfficersData,
    error: ownerOfficersError,
  } = useNonIndividualOwnersAndOfficersQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(ownersOfficersqueryVariables ?? {}),
    },
  });

  const {
    loading: ascBusinessesLoading,
    data: ascBusinessesData,
    error: ascBusinessesError,
  } = useIndividualsAssociatedBusinessQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(ascBusinessesQueryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME);

  useAppErrorHandler(ownerOfficersError || ascBusinessesError || exportError);

  return (
    <>
      <SC.ProgressIndicator
        open={ownerOfficersLoading || ascBusinessesLoading || exportLoading}
      />
      <SC.Card>
        <SC.Table
          title="Associated Registered Non-Individuals"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => null}
          persistenceId="5b85031b-473c-470e-b24e-b067d7367d49"
          columns={associatedBusinessescolumns}
          data={
            ascBusinessesData?.rtcs_db_Fnura_IndividualsAssociatedBusiness ?? []
          }
          exportData={(columnz: ColumnInstance[]) =>
            exportDataFn(
              columnz,
              undefined,
              ascBusinessesData?.rtcs_db_Fnura_IndividualsAssociatedBusiness ??
                []
            )
          }
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          title="Owners and Officers"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => null}
          persistenceId="5b85031b-473c-470e-b24e-b067d7367d49"
          columns={ownersAndOfficersColumns}
          data={
            ownerOfficersData?.rtcs_db_Fnura_CombinedOfficersAndOwners ?? []
          }
          exportData={(columnz: ColumnInstance[]) =>
            exportDataFn(
              columnz,
              undefined,
              ownerOfficersData?.rtcs_db_Fnura_CombinedOfficersAndOwners ?? []
            )
          }
        />
      </SC.Card>
    </>
  );
};

export default IndividualBusinesses;
