import React from "react";

// GraphQL
import { Rtcs_Db_Rmatch_Individual } from "graphql/hasura/types-and-hooks";

// Components
import GraphChart from "components/graph-chart/graph-chart.component";

// Assets
import SC from "./rmatch-tab.styles";

export interface RMatchTabProps {
  rmatchIndividual?: Rtcs_Db_Rmatch_Individual;
}

const RMatchTab: React.FC<RMatchTabProps> = ({ rmatchIndividual }) => {
  const [rMatchScore, elements] = React.useMemo(() => {
    if (rmatchIndividual) {
      const { MatchScore, Gml } = rmatchIndividual;

      if (Gml) return [MatchScore as number, JSON.parse(Gml).elements];
    }
    return [undefined, undefined];
  }, [rmatchIndividual]);

  if (!elements) return null;

  return (
    <SC.Container>
      {rMatchScore !== undefined && (
        <SC.Title>
          <b>rMatch Score:</b> {rMatchScore}
        </SC.Title>
      )}
      <GraphChart elements={elements} />
    </SC.Container>
  );
};

export default RMatchTab;
