/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  withStyles,
  makeStyles,
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  TypographyProps,
  IconButton as IconButtonBase,
  List,
  Grid,
  AppBar,
  Divider,
  CircularProgress,
} from "@material-ui/core";

const Accordion = withStyles({
  root: {
    width: "100%",
  },
})(AccordionBase);

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
    borderTopWidth: 0,
    borderBottomWidth: 2,
  },
})(AccordionSummaryBase);

interface CustomTypographyProps extends TypographyProps {
  type?: string;
}

const useLabelStyles = makeStyles((theme: any) => ({
  root: {
    fontWeight: (props: CustomTypographyProps) =>
      props.type === "bold" ? "bold" : 100,
    marginRight: theme.spacing(2),
    fontSize: theme.accordionHeader.mainHeader.fontSize,
  },
}));

const Label = (props: CustomTypographyProps): JSX.Element => {
  const { ...muiProps } = props;
  const classes = useLabelStyles(props);
  return <Typography className={classes.root} {...muiProps} />;
};

const LabelTitle = withStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(3),
    fontSize: theme.accordionHeader.groupHeader.fontSize,
    textAlign: "center",
    fontWeight: "bold",
  },
}))(Typography);

const LabelButtonText = withStyles(() => ({
  root: {
    fontSize: "0.7rem",
    textAlign: "center",
  },
}))(Label);

const IconButton = withStyles(() => ({
  root: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}))(IconButtonBase);

const ProgressIndicator = CircularProgress;

export default {
  AppBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  LabelButtonText,
  LabelTitle,
  IconButton,
  List,
  Grid,
  Divider,
  ProgressIndicator,
};
