/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useParams,
  RouteProps,
} from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Rmatch_NonIndividualArgs,
  useGetRMatchNonIndividualQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler, UIError, UIErrorCodes } from "errors/app.errors";
import { Source } from "components/graph-chart/graph-chart.utils";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import TabSubHeader from "components/tab-sub-header/tab-sub-header.component";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import LandlordIncomeDetail from "pages/non-individual-tab/analysis-tab/landlord-income-detail/landlord-income-detail.component";
import TenantIncomeDetail from "pages/non-individual-tab/analysis-tab/tenant-income-detail/tenant-income-detail.component";
import RMatchTab from "../rmatch-tab/rmatch-tab.component";
import NonIndividualTaxHistory from "../tax-history-tab/main/tax-history.component";
import TaxHistoryDetails from "../tax-history-tab/details/tax-history-details.component";
import IdentificationTab from "../identification-tab/identification-tab.component";
import NonIndividualNotes from "../notes-tab/notes-tab.component";
import AffiliatesAndIndividuals from "../affiliates-and-individuals/affilates-and-individuals.component";
import NonIndividualProperties from "../properties-tab/properties-tab.component";
import NonIndividualOwnersOfficers from "../owners-officers-tab/owners-officers.component";
import AnalysisTab from "../analysis-tab/analysis-tab.component";
import TabHeaderUra from "./non-individual-tab-header-ura.component";
import TabHeaderKcca from "./non-individual-tab-header-kcca.component";

// Styles
import SC from "./non-individual-tab.styles";
import BalanceSheetDetails from "../tax-history-tab/main/balance-sheet-details/balance-sheet-details.component";
import NonIndividualLandlordRentalIncome from "../landlord-rental-income/non-individual-landlord-rental-income.component";

export interface NonIndividualTabRouteParams {
  repId: string;
}

const NonIndividualTabPage: React.FC = () => {
  const { repId } = useParams<NonIndividualTabRouteParams>();
  const { path } = useRouteMatch();
  const pathArr = path.split("/");
  pathArr.pop();
  const location = useLocation();
  const { pathname } = location;
  const pathSplit = pathname.split("/").slice(1);
  const appPermissionValidator = useAppPermissionValidator();

  const queryVariablesRMatchNonIndividual: Query_RootRtcs_Db_Rmatch_NonIndividualArgs =
    {
      where: repId.startsWith("NON")
        ? { Rnid: { _eq: repId } }
        : { UraTinNo: { _like: `%${repId}%` } },
    };
  const { data: rMatchNonIndividualData, error: rMatchNonIndividualError } =
    useGetRMatchNonIndividualQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      variables: {
        params: jsonToStringQueryVariables(queryVariablesRMatchNonIndividual),
      },
    });
  const rmatchNonIndividual =
    rMatchNonIndividualData?.rtcs_db_FnRPT_NonIndividualComposite?.[0];

  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();

  const mainContainerPositionY = mainContainerPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;

  const handleError = useAppErrorHandler(rMatchNonIndividualError);

  // In order to avoid the warning raised in the browser inspector's console:
  // "Cannot update a component (ErrorBoundary) while rendering a different component (IndividualTabPage)",
  // we need to call handleError inside of useEffect.
  React.useEffect(() => {
    if (
      rMatchNonIndividualData &&
      rMatchNonIndividualData.rtcs_db_FnRPT_NonIndividualComposite.length === 0
    ) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Selection doesn't exist.`
        )
      );
    }
  }, [rMatchNonIndividualData, handleError]);

  // const rnid = repId;
  const rnid = rmatchNonIndividual?.Rnid ?? "";
  const tinNo = rmatchNonIndividual?.UraTinNo ?? "";
  const customerId = rmatchNonIndividual?.CustomerId ?? "";

  const tabsData = [
    { label: "rMatch", path: "rmatch", value: 0 },
    { label: "Identification", path: "identification", value: 1 },
    { label: "Tax History", path: "tax-history", value: 2 },
    {
      label: "Tenant Details",
      path: "landlord-rental-income",
      value: 3,
    },
    {
      label: "Individuals/Affiliates",
      path: "individuals-and-affiliates",
      value: 4,
    },
    { label: "Owners/Officers", path: "owners-officers", value: 5 },
    { label: "Properties", path: "properties", value: 6 },
    { label: "Analysis", path: "analysis", value: 7 },
  ];
  const routes: RouteProps[] = [
    {
      path: `${path}rmatch`,
      exact: true,
      render: () => <RMatchTab rmatchNonIndividual={rmatchNonIndividual} />,
    },
    {
      path: `${path}owners-officers`,
      exact: true,
      render: () => <NonIndividualOwnersOfficers tin={tinNo} />,
    },
    {
      path: `${path}tax-history`,
      exact: true,
      render: () => <NonIndividualTaxHistory tin={tinNo} />,
    },
    {
      path: `${path}landlord-rental-income`,
      exact: true,
      render: () => <NonIndividualLandlordRentalIncome tin={tinNo} />,
    },
    {
      path: `${path}identification`,
      exact: true,
      render: () => (
        <IdentificationTab rnid={rnid} customerIds={customerId} tin={tinNo} />
      ),
    },
    {
      path: `${path}tax-history/:rtnNO`,
      exact: true,
      render: () => <TaxHistoryDetails />,
    },
    {
      path: `${path}tax-history/:rtnNO/balance-sheet`,
      exact: true,
      render: () => <BalanceSheetDetails />,
    },
    {
      path: `${path}individuals-and-affiliates`,
      exact: true,
      render: () => <AffiliatesAndIndividuals rnid={rnid} />,
    },
    {
      path: [`${path}properties/:rentedStatus`, `${path}properties`],
      exact: true,
      render: () => <NonIndividualProperties rnid={rnid} />,
    },
    {
      path: `${path}analysis`,
      exact: true,
      render: () => <AnalysisTab Rnid={rnid} Tin={tinNo} />,
    },
    {
      path: `${path}analysis/landlord/:returnNo/detail`,
      exact: true,
      render: () => <LandlordIncomeDetail />,
    },
    {
      path: `${path}analysis/tenant/:landlordTin/:taxYear/detail`,
      exact: true,
      render: () => <TenantIncomeDetail />,
    },
  ];

  if (appPermissionValidator?.(APP_PERMISSION.NON_INDIVIDUALS_VIEW_NOTES)) {
    tabsData.push({ label: "Notes", path: "notes", value: 8 });
    routes.push({
      path: `${path}notes/:noteID?`,
      exact: true,
      render: () => <NonIndividualNotes />,
    });
  }

  routes.push({
    render: () => <>Content Not Found</>,
  });

  const source = rmatchNonIndividual?.Source ?? "";
  const uraName = rmatchNonIndividual?.UraName;
  const kccaName = rmatchNonIndividual?.KccaName;
  let headerComponent = null;
  let breadcrumbComponent = null;

  if (rnid && source) {
    headerComponent =
      (source as Source) === "URA" ? (
        <TabHeaderUra rnid={rnid} tinNos={tinNo} />
      ) : (
        <TabHeaderKcca rnid={rnid} customerIds={customerId} />
      );
  }

  if (
    pathSplit[2] === "analysis" &&
    (pathSplit[3] === "tenant" || pathSplit[3] === "landlord")
  ) {
    breadcrumbComponent = (
      <Breadcrumb
        staticRoutes={[
          {
            index: 0,
            path: "/non-individuals",
            text: "non individuals",
          },
          {
            index: 1,
            path: `/non-individuals/${pathSplit[1]}/analysis`,
            text: `${uraName ?? kccaName ?? "..."}`,
          },
          {
            index: 2,
            path: `/non-individuals/${pathSplit[1]}/analysis`,
            text: "analysis",
          },
          {
            index: 3,
            path: `/non-individuals/${pathSplit[1]}/analysis`,
            text: pathSplit[3],
          },
          { index: 4, path: "#", text: "detail" },
        ]}
      />
    );
  } else {
    breadcrumbComponent = (
      <Breadcrumb label={`${uraName ?? kccaName ?? "..."}`} />
    );
  }

  return (
    <SC.Container>
      {breadcrumbComponent}
      <SC.ListSubheader>
        {headerComponent}
        <TabSubHeader tabs={tabsData} />
      </SC.ListSubheader>
      <SC.TabsContainer
        maxWidth={false}
        ref={mainContainerRef}
        maxHeight={containerHeight}
      >
        <Switch>
          {routes.map((route) => {
            const key = _.isArray(route.path)
              ? route.path[0]
              : route.path ?? "Content-Not-Found";

            return <Route key={key} {...route} />;
          })}
        </Switch>
      </SC.TabsContainer>
    </SC.Container>
  );
};

export default NonIndividualTabPage;
