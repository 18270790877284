/* eslint-disable no-alert */
import React, { useMemo } from "react";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs,
  useGetPaginatedUgandaRegistrationQuery,
  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column,
  Order_By,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  getAccessorType,
  UgandaRegistrationColumns,
} from "./uganda-registration.schema";

// Assets
import SC from "./uganda-registration.styles";

const TABLE_NAME = "rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB";

const UgandaRegistration: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);
  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const { data, loading, error } = useGetPaginatedUgandaRegistrationQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(error || exportError);

  const UgandaRegistrationData = React.useMemo(() => {
    const UgandaRegData =
      data?.rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB ?? [];
    return UgandaRegData;
  }, [data]);

  const columns = React.useMemo(() => UgandaRegistrationColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
      offset: 0,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.BusinessName,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm[0].value ??
      "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [handlePaginatedFetchData, loading, totalRowsCount]
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
        offset: 0,
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.UgandaRegistration>
      <Breadcrumb />
      <Table
        title="Owners and Shareholders"
        persistenceId="0dc8fe17-e48b-48fd-8ff9-bcbe47682dae"
        columns={columns}
        data={UgandaRegistrationData}
        onAction={() => alert("under construction")}
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        headerPanel={
          <SC.Title>MDA - Uganda Registration Services Bureau</SC.Title>
        }
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "Entity Name",
                columnNames: [
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.BusinessName,
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.CompanyName,
                ],
              },
              {
                label: "First Name",
                columnNames: [
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.PartnersFirstname,
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.DirectorFirstname,
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.ShareholderFirstname,
                ],
              },
              {
                label: "Surname",
                columnNames: [
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.PartnersSurname,
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.DirectorSurname,
                  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.ShareholdersSurname,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        paginationControlled={paginationControlled}
        stickyHeader
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
      <ProgressIndicator open={loading || exportLoading} />
    </SC.UgandaRegistration>
  );
};

export default UgandaRegistration;
