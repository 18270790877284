/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React from "react";
import { useWindowSize } from "@react-hook/window-size";
import Cytoscape from "cytoscape";
import Dagre from "cytoscape-dagre";
import CytoscapeComponent from "react-cytoscapejs";
// import _ from "lodash";

// Assets
import SC from "./graph-chart.styles";
import styles from "./graph-chart.module.css";

Cytoscape.use(Dagre);

export interface GraphChartProps {
  elements:
    | Cytoscape.ElementDefinition[]
    | {
        nodes: Cytoscape.ElementDefinition[];
        edges: Cytoscape.ElementDefinition[];
      };
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
}

export const GraphChart: React.FC<GraphChartProps> = ({
  elements,
  width,
  height,
}) => {
  const cyRef = React.useRef<Cytoscape.Core>();
  const normalizedElements = React.useMemo(
    () => CytoscapeComponent.normalizeElements(elements),
    [elements]
  );
  // const normalizedElements = React.useMemo(() => {
  //   const positions: Record<MdaType, Cytoscape.Position[]> = {
  //     ura: [],
  //     kcca: [],
  //   };
  //   const X_SEPARATION = 250;
  //   const Y_POSITIONS: Record<MdaType, number> = {
  //     ura: 0,
  //     kcca: 150,
  //   };
  //   const eleList = CytoscapeComponent.normalizeElements(elements);

  //   eleList.forEach((ele) => {
  //     // Computing positions to nodes
  //     if (_.isEmpty(ele.data.source)) {
  //       const mdaName = ele.data.MdaName as MdaType;
  //       let posX = 0;
  //       const mdaPositions = positions[mdaName];

  //       if (mdaPositions?.length > 0) {
  //         const lastPos = mdaPositions[mdaPositions.length - 1];

  //         posX = lastPos.x > 0 ? -lastPos.x : -lastPos.x + X_SEPARATION;
  //       }

  //       const position = { x: posX, y: Y_POSITIONS[mdaName] };

  //       mdaPositions?.push(position);
  //       ele.position = position;
  //     }
  //   });

  //   return eleList;
  // }, [elements]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  // const windowHeight = useWindowHeight();
  const windowSize = useWindowSize();
  // const chartWidth = width ?? windowWidth - (containerPosition?.x ?? 0);
  const [chartSize, setChartSize] = React.useState<
    | {
        width: React.CSSProperties["width"];
        height: React.CSSProperties["height"];
      }
    | undefined
  >();

  React.useEffect(() => {
    const containerPosition = containerRef.current?.getBoundingClientRect();
    setChartSize({
      // width: width ?? containerPosition?.width,
      width: width ?? windowSize[0] - (containerPosition?.x ?? 0),
      height: height ?? windowSize[1] - (containerPosition?.y ?? 0),
    });
  }, [height, width, windowSize]);

  React.useEffect(() => {
    if (chartSize) cyRef.current?.fit();
  }, [chartSize]);

  return (
    <SC.Container ref={containerRef}>
      {!!chartSize && (
        <CytoscapeComponent
          cy={(cy) => {
            cyRef.current = cy;
          }}
          elements={normalizedElements}
          layout={{
            // @ts-ignore
            name: "dagre",
            nodeSep: 100, // default 50
            // minLen: () => 1,
            // rankDir: "LR",
            spacingFactor: 1.25,
            nodeDimensionsIncludeLabels: true,
          }}
          className={styles.GraphChart}
          style={{
            width: chartSize.width,
            height: chartSize.height,
          }}
          stylesheet={SC.Stylesheet}
          maxZoom={2}
          autoungrabify
        />
      )}
    </SC.Container>
  );
};

export default GraphChart;
