// Dependencies
import React from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { useGetPaginatedLandlordIncomeDetailQuery } from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { CLIENT_NAME } from "graphql/client";

// Component
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { landlordIncomeDetailColumns } from "./landlord-income-detail.schema";

// Assets
import SC from "./landlord-income-detail.styles";

const LandlordIncomeDetail: React.FC = () => {
  const { returnNo } = useParams<{ returnNo: string }>();
  const landlordIncomeDetailQueryVariables = {
    where: { RtnNo: { _eq: returnNo } },
  };

  const { data, loading, error } = useGetPaginatedLandlordIncomeDetailQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !landlordIncomeDetailQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(
        landlordIncomeDetailQueryVariables ?? {}
      ),
      aggregateParams: jsonToStringQueryVariables({
        where: landlordIncomeDetailQueryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(error);

  return (
    <SC.Container maxWidth={false}>
      <ProgressIndicator open={loading} />
      <SC.Card>
        <SC.Table
          isPaginationHidden
          title="Landlor Income Detail"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => {}}
          columns={landlordIncomeDetailColumns}
          data={data?.rtcs_db_Fnura_LandlordIncomeDetail ?? []}
          persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non-landlord-income-detail"
        />
      </SC.Card>
    </SC.Container>
  );
};

export default LandlordIncomeDetail;
