// Dependencies
import { Box as BoxBase } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

const Box = BoxBase;

export default {
  Box,
  RoomIcon,
};
