/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography,
  Box as ContainerBase,
  withStyles,
} from "@material-ui/core";
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const Container = withStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
  },
}))(ContainerBase);

const Box = ContainerBase;

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
}))(Typography);

export default {
  Typography,
  Table,
  Title,
  Box,
  ProgressIndicator,
  Container,
};
