import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { Box } from "@material-ui/core";

export default {
  Table,
  ProgressIndicator,
  Box,
  Breadcrumb,
};
