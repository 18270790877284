/* eslint-disable import/prefer-default-export */
import { TableColumn } from "components/table/table.component";
import { LandlordIncomeDetailFragment } from "graphql/hasura/types-and-hooks";

export const landlordIncomeDetailColumns: TableColumn<LandlordIncomeDetailFragment>[] =
  [
    {
      Header: "Return ID No.",
      accessor: "RtnRentalIncomeId",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Return No.",
      accessor: "RtnNo",
      align: "center",
      format: "string",
    },
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Taxpayer Name",
      accessor: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Return Date",
      accessor: "RtnDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period From",
      accessor: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period To",
      accessor: "RtnToDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenant TIN",
      accessor: "TenantTin",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Name",
      accessor: "TenantName",
      align: "left",
      format: "string",
    },
    {
      Header: "Premises Location",
      accessor: "PremiseLocation",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenancy Period From",
      accessor: "TenancyPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenancy Period To",
      accessor: "TenancyPeriodTo",
      align: "center",
      format: "date",
    },
    {
      Header: "Rent Reported",
      accessor: "RentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Is Amendment",
      accessor: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Is Resident",
      accessor: "IsResident",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Tax Period",
      accessor: "RtnPeriodYear",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Tax Date",
      accessor: "RtnDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenant From Date",
      accessor: "RtnFromDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenant To Date",
      accessor: "RtnToDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      Header: "Gross Rent Income",
      accessor: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Chargeable Rent Income",
      accessor: "AssessedChrgbleRentlIncm",
      align: "right",
      format: "currency",
    },
    {
      Header: "Rent Income",
      accessor: "RentincIncome",
      align: "right",
      format: "currency",
    },
  ];
