/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container as ContainerBase,
  ContainerProps,
  withStyles,
  TableContainer as TableContainerBase,
  Table as TableBase,
  TableHead as TableHeadBase,
  TableFooter as TableFooterBase,
  TableRow as TableRowBase,
  TableCell as TableCellBase,
  TableBody as TableBodyBase,
  TableSortLabel as TableSortLabelBase,
  IconButton,
  Grid as GridBase,
  Box as BoxBase,
  BoxProps,
  Checkbox,
  TableCellProps,
} from "@material-ui/core";

interface CustomBoxProps extends BoxProps {
  pane?: boolean;
}

const TableFooter = TableFooterBase;

const useRightPaneBoxStyles = makeStyles(() => ({
  root: {
    width: (props: CustomBoxProps) =>
      props.pane ? "calc(100% - 23rem)" : "100%",
  },
}));

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
  expanded?: boolean;
}

const useContainerStyles = makeStyles({
  root: {
    overflowX: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    height: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
  },
});

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <ContainerBase className={classes.root} {...muiProps} ref={ref} />;
  }
);

const RightPaneBox = (props: CustomBoxProps): JSX.Element => {
  const { pane, ...muiProps } = props;
  const classes = useRightPaneBoxStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const useLeftPaneBoxStyles = makeStyles(() => ({
  root: {
    width: "23rem",
    minWidth: "23rem",
    overflowY: "auto",
    display: (props: CustomBoxProps) => (props.pane ? "inherit" : "none"),
  },
}));

const LeftPaneBox = (props: CustomBoxProps): JSX.Element => {
  const { pane, ...muiProps } = props;
  const classes = useLeftPaneBoxStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const TopPaneBox = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
}))(BoxBase) as typeof BoxBase;

const ExpansionStyles = makeStyles(() => ({
  expanded: {
    position: "absolute",
    height: "100vh",
    background: "#fff",
    zIndex: 1200,
    width: "100vw",
    top: 0,
    left: 0,
  },
  collapsed: {},
}));

const ExpansionContainer: React.FC<CustomContainerProps> = (props) => {
  const { expanded, ...muiProps } = props;
  const classes = ExpansionStyles();
  return (
    <Container
      className={expanded ? `${classes.expanded}` : classes.collapsed}
      {...muiProps}
    />
  );
};

const TableContainer = TableContainerBase;

const Table = withStyles((theme: any) => ({
  root: {
    tableLayout: "auto",
    backgroundColor: theme.palette.background.default,
  },
}))(TableBase);

const Grid = GridBase;

const Box = BoxBase;

const Resizer = withStyles(() => ({
  root: {
    minWidth: ".5rem !important",
    width: ".5rem !important",
    cursor: "ew-resize !important",
    overflowX: "auto",
    backgroundColor: "transparent",
    padding: "0",
    flex: "0 0 auto !important",
  },
}))(BoxBase);

const useTableStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: 0,
  },
  tableContainer: {
    overflowX: "auto",
  },
}));

const TableHead = withStyles((theme: any) => ({
  root: {
    borderBottom: `0.05rem solid ${theme.border.color}`,
    top: 0,
    position: "sticky",
    zIndex: 1,
  },
}))(TableHeadBase);

const TableBody = TableBodyBase;

const TableRow = withStyles((theme: any) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.table.tableRow.evenRowBackgroundColor,
    },
    "& .MuiTableHead-root": {
      zIndex: 0,
    },
    "& .MuiGrid-align-items-xs-baseline": {
      backgroundColor: "white",
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    "& .MuiContainer-root": {
      backgroundColor: "white",
    },
  },
}))(TableRowBase);

const TableCell = withStyles((theme: any) => ({
  root: {
    overflow: "hidden",
    cursor: "default !important",
    whiteSpace: "nowrap",
    border: "none",
    textOverflow: "ellipsis",
    paddingRight: "0.4rem",
  },
  body: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& a": {
      textDecoration: theme.table.link.textDecoration,
      color: theme.table.link.color,
    },
  },
  head: {
    fontWeight: theme.table.head.fontWeight,
    fontSize: theme.table.head.fontSize,
    position: "relative",
  },
  footer: {
    fontWeight: theme.table.head.fontWeight,
    color: "black",
    fontSize: theme.table.head.fontSize,
  },
}))(TableCellBase);

interface TableCellHeaderProps extends BoxProps {
  align?: TableCellProps["align"];
}

const alignToJustifyContentMap: Record<
  NonNullable<TableCellProps["align"]>,
  React.CSSProperties["justifyContent"]
> = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  justify: "normal",
  inherit: "inherit",
};

const useTableCellHeaderStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: ({ align }: TableCellHeaderProps) =>
      align ? alignToJustifyContentMap.center : "normal",
    "& span": {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      whiteSpace: "normal",
      width: "auto !important",
      minWidth: "auto !important",
    },
  },
}));

const TableCellHeader = (props: TableCellHeaderProps): JSX.Element => {
  const { align, ...muiProps } = props;
  const classes = useTableCellHeaderStyles(props);

  return <BoxBase className={classes.root} {...muiProps} />;
};

const TableSortLabel = withStyles((theme: any) => ({
  root: {
    color: `${theme.table.icon.default.color} !important`,
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      fontSize: theme.spacing(3),
      padding: "0.1rem",
    },
  },
  icon: {
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: `${theme.palette.primary.main} !important`,
  },
}))(TableSortLabelBase);

const ExpanderIconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(1.8),
  },
}))(IconButton);

const VisibilityIcon = withStyles(() => ({
  root: {
    paddingTop: 0,
    zIndex: 0,
  },
}))(Checkbox);

export default {
  Container,
  ExpansionContainer,
  TableContainer,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
  TableCellHeader,
  TableSortLabel,
  ExpanderIconButton,
  Grid,
  Box,
  LeftPaneBox,
  TopPaneBox,
  RightPaneBox,
  useTableStyles,
  Resizer,
  VisibilityIcon,
};
