/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";
import { NwscCustomersFragment } from "graphql/hasura/types-and-hooks";

export type NwscCustomersTableSchema = Omit<
  NwscCustomersFragment,
  "__typename"
>;

export const NWSC_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NwscCustomersTableSchema,
  "string" | "number"
> = {
  Customerreference: "number",
  CustomernamePay: "string",
  Customerphoneno1Pay: "string",
  Customerphoneno1Cons: "string",
  AddressPay: "string",
  AreaPay: "string",
  LatitudePay: "string",
  LongitudePay: "string",
};

export const getAccessorType = (
  accessor:
    | keyof NwscCustomersTableSchema
    | Accessor<NwscCustomersTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NWSC_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nwscCustomersTableColumns: TableColumn<NwscCustomersTableSchema>[] =
  [
    {
      Header: "Customer Reference",
      accessor: "Customerreference",
      align: "center",
      sort: true,
    },
    {
      Header: "Customer Name",
      accessor: "CustomernamePay",
      align: "center",
      format: "string",
    },
    {
      Header: "Phone No. 1",
      accessor: "Customerphoneno1Pay",
      align: "center",
      format: "string",
    },
    {
      Header: "Phone No. 2",
      accessor: "Customerphoneno1Cons",
      align: "center",
      format: "string",
    },
    {
      Header: "Address",
      accessor: "AddressPay",
      align: "center",
      format: "string",
    },
    {
      Header: "Area",
      accessor: "AreaPay",
      align: "center",
      format: "string",
    },
    {
      Header: "Latitude",
      accessor: "LatitudePay",
      align: "center",
    },
    {
      Header: "Longitude",
      accessor: "LongitudePay",
      align: "center",
    },
  ];
