/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { MasterIndividualDataFragment } from "graphql/hasura/types-and-hooks";
import SC from "./individual.styles";

export type MasterIndividualSchema = Omit<
  MasterIndividualDataFragment,
  "__typename"
>;

// @ts-ignore
export const MasterIndividualSchemaMap: Record<
  keyof MasterIndividualSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Firstname: "string",
  Surname: "string",
  Nationalid: "string",
  Uratinno: "string",
  Numproperties: "number",
  Numrentedproperties: "number",
  Rtnperiodyear: "string",
  Sumadjustedincome: "number",
  Isincomesrcrental: "string",
  Grossrentalincome: "number",
  Riskscore: "number",
  Riskcode: "string",
};

export const getAccessorType = (
  accessor:
    | keyof MasterIndividualSchema
    | Accessor<MasterIndividualSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MasterIndividualSchemaMap[accessor];
};

export const individualTableColumns: TableColumn<MasterIndividualSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "right",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
  },

  {
    Header: "First Name",
    accessor: "Firstname",
    align: "left",
  },
  {
    Header: "Surname",
    accessor: "Surname",
    align: "left",
  },
  {
    Header: "National ID",
    accessor: "Nationalid",
    align: "left",
  },
  {
    Header: "TIN No.",
    accessor: "Uratinno",
    align: "left",
  },

  {
    Header: "Is Registered for Rental Income?",
    accessor: "Isincomesrcrental",
    align: "left",
  },
  {
    Header: "Risk Score",
    accessor: "Riskscore",
    align: "right",
  },
  {
    Header: "Risk Codes",
    accessor: "Riskcode",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/analysis`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Estimated Gross Rental Income Per Sources",
    accessor: "Sumadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Declared Gross Rental Income Per Return",
    accessor: "Grossrentalincome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Tax Year",
    accessor: "Rtnperiodyear",
    align: "right",
  },
  {
    Header: "Total No. Rental Properties",
    accessor: "Numrentedproperties",
    align: "right",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Total No. of Properties",
    accessor: "Numproperties",
    align: "right",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Map",
    // @ts-ignore
    accessor: "n/a",
    align: "center",
    Cell: ({
      row: {
        // @ts-ignore
        values: { Rnid },
      },
    }) => {
      return (
        <Link
          to={`/individuals/${Rnid}/properties?mapview=visible`}
          color="inherit"
        >
          <SC.RoomIcon color="primary" />
        </Link>
      );
    },

    disableSortBy: true,
  },
];
