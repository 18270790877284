// Dependencies
import { withStyles, Box as BoxBase } from "@material-ui/core";

const Box = withStyles(() => ({
  root: {
    overflowY: "scroll",
    height: "100%",
  },
}))(BoxBase);

export default {
  Box,
};
