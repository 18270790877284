/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";
import { MlhudFragment } from "graphql/hasura/types-and-hooks";

export type MlhudSchema = Omit<MlhudFragment, "__typename">;

// @ts-ignore
export const MlhudSchemaMap: Record<keyof MlhudSchema, "string" | "number"> = {
  Firstname: "string",
  Middlename: "string",
  Fullname: "string",
  Lastname: "string",
  Gender: "string",
  Titlenumber: "string",
  Blocknumber: "string",
  Plotno: "string",
  Roadname: "string",
  Address: "string",
  Subcounty: "string",
  County: "string",
  District: "string",
  Municipality: "string",
  Tenuretype: "string",
  Legalarea: "number",
  Legalareaunittype: "string",
  Volume: "string",
  Folio: "string",
  Titlelocation: "string",
  Baunittype: "string",
  Landtype: "string",
  Landusetype: "string",
  Condounitfactor: "string",
  Condoplanno: "string",
  Lafnumber: "string",
  Phone: "string",
  Description: "string",
  Registrationdatetime: "string",
  Cadlatitude: "string",
  Cadlongitude: "string",
  Cadlotnumber: "string",
  Cadblocknumb: "string",
  Cadroadname: "string",
  Cadcounty: "string",
  MatchResult: "string",
};

export const getAccessorType = (
  accessor: keyof MlhudSchema | Accessor<MlhudSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MlhudSchemaMap[accessor];
};

export const mlhudTableColumns: TableColumn<MlhudSchema>[] = [
  {
    Header: "Full Name",
    accessor: "Fullname",
    align: "left",
    sort: false,
  },
  {
    Header: "First Name",
    accessor: "Firstname",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Middle Name",
    accessor: "Middlename",
    align: "left",
    sort: false,
  },
  {
    Header: "Last Name",
    accessor: "Lastname",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Gender",
    accessor: "Gender",
    align: "left",
    sort: false,
  },
  {
    Header: "Title Number",
    accessor: "Titlenumber",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Block",
    accessor: "Blocknumber",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Plot",
    accessor: "Plotno",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Road Name",
    accessor: "Roadname",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Address",
    accessor: "Address",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Subcounty",
    accessor: "Subcounty",
    align: "left",
    sort: false,
  },
  {
    Header: "County",
    accessor: "County",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "District",
    accessor: "District",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Municipality",
    accessor: "Municipality",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Tenure Type",
    accessor: "Tenuretype",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Legal Area",
    accessor: "Legalarea",
    align: "right",
    sort: false,
  },
  {
    Header: "Legal Area Unit Type",
    accessor: "Legalareaunittype",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Volume",
    accessor: "Volume",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Folio",
    accessor: "Folio",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Title Location",
    accessor: "Titlelocation",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Ba Unit Type",
    accessor: "Baunittype",
    align: "left",
    sort: false,
  },
  {
    Header: "Land Type",
    accessor: "Landtype",
    align: "left",
    sort: false,
  },
  {
    Header: "Land Use Type",
    accessor: "Landusetype",
    align: "left",
    sort: false,
  },
  {
    Header: "Condo Unit Factor",
    accessor: "Condounitfactor",
    align: "right",
    sort: false,
  },
  {
    Header: "Condo Plan No.",
    accessor: "Condoplanno",
    align: "center",
    sort: false,
    format: "string",
  },
  {
    Header: "Laf No.",
    accessor: "Lafnumber",
    align: "left",
    sort: false,
  },
  {
    Header: "Phone",
    accessor: "Phone",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Description",
    accessor: "Description",
    align: "left",
    sort: false,
  },

  {
    Header: "Registration",
    accessor: "Registrationdatetime",
    align: "center",
    sort: false,
    format: "date",
  },

  {
    Header: "Latitude",
    accessor: "Cadlatitude",
    align: "center",
    sort: false,
  },

  {
    Header: "Longitude",
    accessor: "Cadlongitude",
    align: "center",
    sort: false,
    format: "string",
  },

  {
    Header: "Card Lot",
    accessor: "Cadlotnumber",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Cad Block Number",
    accessor: "Cadblocknumb",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Cad Road",
    accessor: "Cadroadname",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Cad County",
    accessor: "Cadcounty",
    align: "left",
    sort: false,
    format: "string",
  },
  {
    Header: "Match",
    accessor: "MatchResult",
    align: "left",
    sort: false,
  },
];
