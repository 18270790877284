/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card as CardBase,
  Typography as TypographyBase,
  Box as BoxBase,
  Grid as GridBase,
  withStyles,
  Theme,
  Container as ContainerBase,
} from "@material-ui/core";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";

const Card = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(CardBase);

const LabelKeyValue = LabelKeyValueBase;

const Container = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: "scroll",
    height: "100%",
  },
}))(ContainerBase);

const Typography = withStyles((theme: any) => ({
  h5: {
    fontSize: theme.card.title.fontSize,
    fontWeight: theme.card.title.fontWeight,
    marginBottom: theme.spacing(2),
  },
  h6: {
    fontSize: theme.card.subtitle.fontSize,
    fontWeight: theme.card.subtitle.fontWeight,
  },
}))(TypographyBase);

const Box = withStyles((theme: Theme) => ({
  root: {
    textAlign: "left",
    marginTop: theme.spacing(3),
  },
}))(BoxBase);

const Grid = GridBase;

export default {
  Card,
  Grid,
  LabelKeyValue,
  Typography,
  Box,
  Container,
};
