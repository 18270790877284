import React from "react";

// Graphql
import {
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  Rtcs_Db_Ph_Ura_RentExpenseDetails,
} from "graphql/hasura/types-and-hooks";

import { getStructure } from "./rental-tax-view-summary.schema";

import SC from "./rental-tax-view-summary.styles";

interface InputProps {
  returns: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData;
  expenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[];
}

const RentalTaxViewSummary: React.FC<InputProps> = ({ returns, expenses }) => {
  return (
    <SC.TaxHistoryAccordion title="Rental Tax View Summary">
      <SC.NestedList data={getStructure(returns, expenses)} />
      <></>
    </SC.TaxHistoryAccordion>
  );
};

export default RentalTaxViewSummary;
