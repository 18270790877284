/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Popper,
  Box,
  Paper as PaperBase,
  Button as ButtonBase,
  withStyles,
  Typography,
  Select as SelectBase,
  MenuItem,
} from "@material-ui/core";

const Paper = withStyles((theme: any) => ({
  root: {
    width: "20rem",
    height: "15rem",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
}))(PaperBase);

const SelectField = withStyles((theme: any) => ({
  root: {
    width: "100%",
    borderWidth: "0px",
    ".MuiInputBase-root": {
      width: "100%",
      paddingBottom: theme.spacing(3),
    },
  },
  select: {
    width: "100%",
  },
  selectMenu: {
    width: "100%",
  },
}))(SelectBase);

const Label = withStyles((theme: any) => ({
  root: {
    fontSize: "1.4286rem",
    width: "100%",
    paddingBottom: theme.spacing(3),
  },
}))(Typography);

const Button = withStyles((theme: any) => ({
  root: {
    alignSelf: "flex-end",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    textTransform: "none",
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    margin: theme.spacing(2),
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
  },
}))(ButtonBase);

export default { Box, Popper, Paper, Label, SelectField, MenuItem, Button };
