export default [
  {
    title: "MDA Reports",
    reports: [
      {
        title: "Uganda Revenue Authority",
        nickname: "URA",
        subtitles: [
          {
            label: "Registration - Individuals",
            type: "individuals",
            url: "reports/MDA/URA/individual-registration",
          },
          {
            label: "Registration - NonIndividuals",
            type: "nonindividuals",
            url: "reports/MDA/URA/non-individual-registration",
          },
          {
            label: "Tax Submission - Individuals",
            type: "individuals",
            url: "reports/MDA/URA/individual-tax-detail",
          },
          {
            label: "Tax Submission - NonIndividuals",
            type: "nonindividuals",
            url: "reports/MDA/URA/non-individual-tax-detail",
          },
          {
            label: "Holding Owners and Officers",
            type: "nonindividuals",
            url: "reports/MDA/URA/holding-owners-and-officers",
          },
          {
            label: "Associated Registered Individuals To NonIndividuals",
            type: "individual to nonindividuals",
            url: "reports/MDA/URA/associated-registered-individuals-to-nonindividuals",
          },
          {
            label: "Associated Registered NonIndividuals To NonIndividuals",
            type: "nonindividuals to nonindividuals",
            url: "reports/MDA/URA/associated-registered-nonindividuals-to-nonindividuals",
          },
          {
            label: "Tenant Reported Rent Paid",
            type: "individuals",
            url: "reports/MDA/URA/tenant-reported-rent-paid",
          },
        ],
      },
      {
        title: "Kampala Capital City Authority",
        subtitles: [
          {
            label: "Property Reports",
            url: "reports/MDA/KCCA/property-registration",
          },
          {
            label: "Customer Reports",
            url: "reports/MDA/KCCA/customer-registration",
          },
        ],
      },
      {
        title: "National Water and Sewerage Corporation",
        subtitles: [
          {
            label: "Payments, Transactions and Consumption",
            url: "reports/MDA/NWSC/national-water-and-sewage-corporation",
          },
        ],
      },
      {
        title: "UMEME",
        subtitles: [
          {
            label: "Subscriber And Property",
            url: "reports/MDA/UMEME/subscriber-and-property",
          },
        ],
      },
      {
        title: "Uganda Registration Services Bureau",
        subtitles: [
          {
            label: "Owners and Shareholders",
            url: "reports/MDA/URSB/uganda-registration-services-bureau",
          },
        ],
      },
      {
        title: "Ministry of Lands, Housing and Urban Development",
        subtitles: [
          {
            label: "Land Registration Report",
            url: "reports/MDA/MLHUD/mlhud-registration",
          },
        ],
      },
      {
        title: "National Identification and Registration Authority",
        subtitles: [
          {
            label: "Nira Registration Report",
            url: "reports/MDA/NIRA-registration",
          },
        ],
      },
      {
        title: "Uganda Communication Commission",
        subtitles: [
          {
            label: "Mobile Registration Data",
            url: "reports/MDA/UCC/mobile-phone-numbers",
          },
        ],
      },
      {
        title: "rCAPTURE REPORTS",
        subtitles: [
          {
            label: "rCAPTURE Properties",
            url: "reports/MDA/rCAPTURE/properties",
          },
        ],
      },
    ],
  },
  {
    title: "Analytic Reports",
    reports: [
      {
        title: "Unregistered Landlords",
        type: "individuals",
        content: ["All landlords who are not registered with the URA"],
        subtitles: [
          {
            label: "Individuals",
            type: "",
            url: "reports/analytics/unregistered-landlords/individuals",
          },
          {
            label: "NonIndividuals",
            type: "",
            url: "reports/analytics/unregistered-landlords/non-individuals",
          },
        ],
      },
      {
        title: "Taxpayers with No Returns",
        type: "individuals",
        content: [
          "Shows rented property owners that have been matched in the URA database and did not file tax returns for at least one year within the last three years",
        ],
        subtitles: [
          {
            label: "Individuals",
            type: "",
            url: "reports/analytics/taxpayers-with-no-returns/individuals",
          },
          {
            label: "NonIndividuals",
            type: "",
            url: "reports/analytics/taxpayers-with-no-returns/non-individuals",
          },
        ],
      },
      {
        title: "Taxpayers with No Income",
        type: "individuals",
        content: [
          "Shows property owners that have been matched in the URA database and did not file rental income in their tax returns for at least one year within the last three years",
        ],
        subtitles: [
          {
            label: "Individuals",
            type: "",
            url: "reports/analytics/taxpayers-with-no-income/individuals",
          },
          {
            label: "NonIndividuals",
            type: "",
            url: "reports/analytics/taxpayers-with-no-income/non-individuals",
          },
        ],
      },
      {
        title: "Taxpayers with Underreported Income",
        type: "individuals",
        content: [
          "Rented property owners that have been matched in the URA database with a declared rental income tax that is 50% less than the estimated total gross rental income",
        ],
        subtitles: [
          {
            label: "Individuals",
            type: "",
            url: "reports/analytics/taxpayers-with-underreported-income/individuals",
          },
          {
            label: "NonIndividuals",
            type: "",
            url: "reports/analytics/taxpayers-with-underreported-income/non-individuals",
          },
        ],
      },
      {
        title: "Excessive Owner Occupancy (Individuals Only)",
        content: [
          "Shows all landlords who claimed owner occupancy for three or more properties",
        ],
        subtitles: [
          {
            label: "Individuals",
            type: "",
            url: "reports/analytics/excessive-owner-occupancy/individuals",
          },
        ],
      },
      {
        title: "Landlord Declared vs Tenant Reported Income",
        content: [
          "Summarizes landlord-declared income and tenant-reported income to the URA",
        ],
        type: "individuals",
        subtitles: [
          {
            label: "Individuals and NonIndividuals",
            type: "",
            url: "reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals",
          },
        ],
      },
      {
        title: "Generate Case Report",
        content: ["Generates Report based on Rnid"],
        type: "report",
        subtitles: [
          {
            label: "Generate Report",
            type: "report",
            url: "reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals",
          },
        ],
      },
    ],
  },
];
