/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { ColumnInstance } from "react-table";
import _ from "lodash";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// styles
import SC from "./transfer-list.styles";

export type Column<D extends Record<string, unknown>> = ColumnInstance<D> & {
  accessor: string;
  Header: string;
  active?: boolean;
  id?: string;
};

export interface TransferListProps<D extends Record<string, unknown>> {
  open: boolean;
  onClose: () => void;
  onSortColumns: (orderIds: string[], hiddenIds: string[]) => void;
  columns: ColumnInstance<D>[];
  visibleColumns: ColumnInstance<D>[];
}

export const TransferList = <D extends Record<string, unknown>>({
  columns,
  visibleColumns,
  open,
  onClose,
  onSortColumns,
}: TransferListProps<D>) => {
  const [checked, setChecked] = React.useState<Column<D>[]>([]);
  const [left, setLeft] = React.useState<Column<D>[]>([]);
  const [right, setRight] = React.useState<Column<D>[]>([]);

  const handleMoveRight = () => {
    if (checked.length > 0) {
      if (!right.some((col) => col.accessor === checked[0].accessor)) {
        setRight(right.concat(checked));
        setLeft(_.differenceWith(left, checked, _.isEqual));
        setChecked([]);
      }
    }
  };

  const handleMoveLeft = () => {
    if (checked.length > 0) {
      if (!left.some((col) => col.accessor === checked[0].accessor)) {
        setLeft(
          left.concat(checked).sort((a, b) => a.Header.localeCompare(b.Header))
        );
        setRight(_.differenceWith(right, checked, _.isEqual));
        setChecked([]);
      }
    }
  };

  function arrayMove(arr: Column<D>[], fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  const handleMoveUp = () => {
    if (checked.length > 0) {
      if (right.length > 1) {
        const index = _.findIndex(right, { accessor: checked[0].accessor });
        if (index !== 0) {
          setRight([...arrayMove(right, index, index - 1)]);
        }
      }
    }
  };

  const handleMoveDown = () => {
    if (checked.length > 0) {
      if (right.length > 1) {
        const index = _.findIndex(right, { accessor: checked[0].accessor });
        if (index !== right.length - 1) {
          setRight([...arrayMove(right, index, index + 1)]);
        }
      }
    }
  };

  const handleToggle = (item: Column<D>) => () => {
    setChecked([item]);
  };

  const handleSortingColumns = () => {
    const orderIds = right.map((column) => {
      return column.id;
    });
    const hiddenIds = left.map((column) => {
      return column.id;
    });
    onSortColumns(orderIds as string[], hiddenIds as string[]);
  };

  const handleResetingColumns = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const hiddenColumns = columns.filter((column) => !!column.hiddenColumn);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const vissibleColumns = columns.filter((column) => !column.hiddenColumn);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setLeft(hiddenColumns.sort((a, b) => a.Header.localeCompare(b.Header)));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setRight(vissibleColumns);
  };

  const handleCloseModal = () => {
    const hiddenColumns = _.differenceWith(columns, visibleColumns, _.isEqual);
    const sortedColumns = _.differenceWith(columns, hiddenColumns, _.isEqual);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setLeft(hiddenColumns.sort((a, b) => a.Header.localeCompare(b.Header)));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setRight(sortedColumns);
    onClose();
  };

  React.useEffect(() => {
    const hiddenColumns = _.differenceWith(columns, visibleColumns, _.isEqual);
    const sortedColumns = _.differenceWith(columns, hiddenColumns, _.isEqual);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setLeft(hiddenColumns.sort((a, b) => a.Header.localeCompare(b.Header)));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setRight(sortedColumns);
  }, [columns, visibleColumns]);

  const customList = (items: Column<D>[]) => (
    <SC.SelectionFields elevation={0}>
      <SC.List dense role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.accessor}-label`;

          return (
            <SC.ListItem
              key={item.id}
              button
              selected={
                _.findIndex(checked, { accessor: item.accessor }) !== -1
              }
              role="listitem"
              onClick={handleToggle(item)}
            >
              <SC.ListItemText id={labelId} primary={item.Header} />
            </SC.ListItem>
          );
        })}
        <SC.ListItem key="unknown" />
      </SC.List>
    </SC.SelectionFields>
  );

  return (
    <SC.Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <SC.PaperContainer elevation={0}>
        <SC.Grid container justify="center" alignItems="center">
          <SC.Grid item xs>
            {customList(left)}
          </SC.Grid>
          <SC.Grid item xs>
            <SC.Grid container direction="column" alignItems="center">
              <SC.IconButton
                size="small"
                disableRipple
                onClick={handleMoveRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                <ChevronRightIcon />
              </SC.IconButton>

              <SC.IconButton
                size="small"
                disableRipple
                onClick={handleMoveLeft}
                disabled={right.length === 0}
                aria-label="move all right"
              >
                <ChevronLeftIcon />
              </SC.IconButton>
            </SC.Grid>
          </SC.Grid>
          <SC.Grid item xs>
            {customList(right)}
          </SC.Grid>
          <SC.Grid item xs>
            <SC.Grid item container direction="column" alignItems="center">
              <SC.IconButton
                size="small"
                disableRipple
                onClick={handleMoveUp}
                disabled={right.length <= 1}
                aria-label="move up"
              >
                <ExpandLessIcon />
              </SC.IconButton>

              <SC.IconButton
                size="small"
                disableRipple
                onClick={handleMoveDown}
                disabled={right.length <= 1}
                aria-label="move down"
              >
                <ExpandMoreIcon />
              </SC.IconButton>
            </SC.Grid>
          </SC.Grid>
        </SC.Grid>
        <SC.MuiDialogActions>
          <SC.Button
            variant="contained"
            size="small"
            autoFocus
            onClick={handleSortingColumns}
            color="primary"
          >
            Save
          </SC.Button>
          <SC.Button
            variant="outlined"
            size="small"
            autoFocus
            onClick={handleResetingColumns}
            color="primary"
          >
            Reset
          </SC.Button>
          <SC.Button
            variant="outlined"
            size="small"
            autoFocus
            onClick={handleCloseModal}
            color="primary"
          >
            Cancel
          </SC.Button>
        </SC.MuiDialogActions>
      </SC.PaperContainer>
    </SC.Dialog>
  );
};

export default TransferList;
