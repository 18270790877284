// Dependencies
import PropTypes from "prop-types";

// Constants
import { MAP } from "./constants";

export const gMapTypeIdType = PropTypes.oneOf(
  Object.keys(MAP.TYPE_ID).map((mti) => MAP.TYPE_ID[mti])
);

export const feedbackStatusShape = PropTypes.shape({
  isSending: PropTypes.bool,
  wasSent: PropTypes.bool,
});
