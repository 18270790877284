import {
  Grid as GridBase,
  Typography as TypographyBase,
  withStyles,
} from "@material-ui/core";

const Grid = withStyles(() => ({}))(GridBase);
const Label = withStyles(() => ({
  root: {
    fontWeight: "bold",
  },
}))(TypographyBase);
const Value = withStyles(() => ({
  root: {
    textAlign: "right",
  },
}))(TypographyBase);

export default {
  Grid,
  Label,
  Value,
};
