/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";
import { SubscriberAndPropertyFragment } from "graphql/hasura/types-and-hooks";

export type TableSchema = Omit<SubscriberAndPropertyFragment, "__typename">;

export const UMEME_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof TableSchema,
  "string" | "number"
> = {
  CustomerName: "string",
  Customerno: "string",
  District: "string",
  MeterNo: "string",
  Street: "string",
  SupplyPointNo: "string",
  TariffDesc: "string",
  Telephone: "string",
  Town: "string",
  XCoord: "number",
  YCoord: "number",
  Longitude: "number",
  Latitude: "number",
};

export const getAccessorType = (
  accessor: keyof TableSchema | Accessor<TableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UMEME_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const TableColumns: TableColumn<TableSchema>[] = [
  {
    Header: "Meter No",
    accessor: "MeterNo",
    align: "center",
    sort: true,
  },
  {
    Header: "Customer No.",
    accessor: "Customerno",
    align: "center",
    format: "string",
  },
  {
    Header: "Supply Point No.",
    accessor: "SupplyPointNo",
    align: "center",
    format: "string",
  },
  {
    Header: "Customer Name",
    accessor: "CustomerName",
    format: "string",
  },
  {
    Header: "District",
    accessor: "District",
    format: "string",
  },
  {
    Header: "Street",
    accessor: "Street",
    format: "string",
  },
  {
    Header: "TariffDesc",
    accessor: "TariffDesc",
    format: "string",
  },
  {
    Header: "Telephone",
    accessor: "Telephone",
    format: "string",
  },
  {
    Header: "Town",
    accessor: "Town",
    format: "string",
  },
  {
    Header: "Latitude",
    accessor: "Latitude",
    align: "center",
  },
  {
    Header: "Longitude",
    accessor: "Longitude",
    align: "center",
  },
  {
    Header: "XCoord",
    accessor: "XCoord",
    format: "string",
  },
  {
    Header: "YCoord",
    accessor: "YCoord",
    format: "string",
  },
];
