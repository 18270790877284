/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Container as ContainerBase,
  Box,
  Typography,
  IconButton as IconButtonBase,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Note from "components/note/note.component";
import NoteCreate from "components/note-create/note-create.component";

const Container = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.pane.backgroundColor,
    textAlign: theme.notes.tab.textAlign,
    padding: theme.notes.tab.padding,
  },
}))(ContainerBase);

const TitleActionsContainer = withStyles((theme: any) => ({
  root: {
    display: theme.notes.toolbarAction.display,
    alignItems: theme.notes.toolbarAction.alignItems,
  },
}))(Box);

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.notes.title.fontWeight,
    fontSize: theme.notes.title.fontSize,
    marginRight: theme.spacing(1),
  },
}))(Typography);

const IconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(1),
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.table.toolbarActions.iconColor,
    },
  },
}))(IconButtonBase);

export default {
  Container,
  TitleActionsContainer,
  Title,
  IconButton,
  Add,
  Note,
  NoteCreate,
  ProgressIndicator,
};
