import { TableColumn } from "components/table/table.component";

import { KccaCustomerAlternateOwnerFragment } from "graphql/hasura/types-and-hooks";

export type KccaCustomerAlternateOwnerTableSchema = Omit<
  KccaCustomerAlternateOwnerFragment,
  "__typename"
>;

export const kccaCustomerAlternateOwnerTableColumns: TableColumn<KccaCustomerAlternateOwnerTableSchema>[] =
  [
    {
      Header: "Contact Email",
      accessor: "ContactEmail",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact Mobile_1",
      accessor: "ContactMobile1",
      align: "right",
      format: "string",
    },
    {
      Header: "Contact Mobile_2",
      accessor: "ContactMobile2",
      align: "right",
      format: "string",
    },
    {
      Header: "Contact Address",
      accessor: "ContactAddress",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact Subcounty",
      accessor: "ContactSubcountryDivision",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact Parish_Ward",
      accessor: "ContactParishWard",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact County",
      accessor: "ContactCountyMunicipality",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact District",
      accessor: "ContactDistrict",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact Country",
      accessor: "ContactCountry",
      align: "center",
      format: "string",
    },
    {
      Header: "Contact Village",
      accessor: "ContactVillageCellZone",
      align: "center",
      format: "string",
    },
  ];
