/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  IconButton as IconButtonBase,
  Grid,
  Paper as PaperBase,
  Box as BoxBase,
} from "@material-ui/core";
import FormatedValue from "components/formatted-value/formatted-value";

const Box = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.accordionBody.backgroundColor,
  },
}))(BoxBase);

const Accordion = withStyles({
  root: {
    borderWidth: 0,
  },
})(AccordionBase);

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
    borderTopWidth: 0,
  },
})(AccordionSummaryBase);

const Label = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
  },
}))(Typography);

const LabelSubtitle = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
    fontSize: theme.accordionBody.subtitle.fontSize,
    fontWeight: theme.accordionBody.subtitle.fontWeight,
    color: theme.label.color,
  },
}))(Typography);

const LabelTitle = withStyles((theme: any) => ({
  root: {
    textAlign: "left",
    fontWeight: theme.accordionBody.subtitle.fontWeight,
    fontSize: theme.accordionBody.title.fontSize,
  },
}))(Typography);

const Paper = withStyles(() => ({
  root: {
    justifyContent: "left",
    borderRadius: 0,
  },
}))(PaperBase);

const LabelButtonText = withStyles(() => ({
  root: {
    fontSize: "0.8rem",
    textAlign: "center",
  },
}))(Label);

const IconButton = withStyles(() => ({
  root: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
  },
}))(IconButtonBase);

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  LabelSubtitle,
  LabelButtonText,
  FormatedValue,
  LabelTitle,
  IconButton,
  Grid,
  Paper,
  Box,
};
