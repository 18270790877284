/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog as ModalBase,
  Grid as GridBase,
  withStyles,
  Paper as PaperBase,
  List,
  ListItem,
  ListItemText,
  Button as ButtonBase,
  IconButton as IconButtonBase,
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";

const Dialog = withStyles(() => ({
  paper: {
    width: "inherit",
    borderRadius: 5,
    padding: "1.14rem",
    paddingRight: "0rem",
  },
}))(ModalBase);

const Grid = withStyles(() => ({
  root: {},
}))(GridBase);

const SelectionFields = withStyles((theme: any) => ({
  root: {
    width: "23.42rem",
    height: "23.42rem",
    border: `0.07rem solid ${theme.border.color}`,
    borderRadius: "0.57rem",
    overflow: "hidden",
    overflowY: "auto",

    "&::-webkit-scrollbar-track": {
      backgroundColor: "inherit",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "inherit",
    },
  },
}))(PaperBase);

const PaperContainer = withStyles(() => ({
  root: {
    width: "inherit",
    maxWidth: "inherit",
  },
}))(PaperBase);

const Paper = PaperBase;

const IconButton = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.table.toolbarActions.iconColor,
    color: theme.palette.background.default,
    marginTop: "1.14rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  disabled: {
    backgroundColor: theme.table.toolbarActions.iconColor,
  },
}))(IconButtonBase);

const Button = withStyles((theme: any) => ({
  root: {
    height: "1.8rem",
    color: theme.table.toolbarActions.iconColor,
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

export default {
  Dialog,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  MuiDialogActions,
  PaperContainer,
  SelectionFields,
  IconButton,
};
